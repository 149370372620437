import { React, useState, useEffect } from 'react';
import { Card } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { getPlan } from '../../utils/getData';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import '../../components/MUI/MuiStyles.css';
import Header from '../MUI/Header';
import Subheader from '../MUI/Subheader';
import PassedButton from '../MUI/PassedButton';

function DashboardSubscription({ sub }) {
    const [plan, setPlan] = useState(null);
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { userDoc } = useStoreState(AppStore);

    const goToCredits = () => {
        navigate('/credits');
    };

    const goToSubscription = () => {
        // setLoading(true);
        const manage = () => {
            window.open(
                //set url based on prod or dev
                process.env.NODE_ENV === 'production'
                    ? 'https://billing.stripe.com/p/login/cN26oKgHQ0jPaM88ww'
                    : 'https://billing.stripe.com/p/login/test_cN27uafW05Yr51K3cc',
                '_blank' // <- This is what makes it open in a new window.
            );
        };
        manage();
        // setLoading(false);
    };

    useEffect(() => {
        // console.log('sub', sub);
        getPlan(sub?.product?.id).then((plan) => {
            // console.log('plan', plan);
            setPlan(plan);
        });
    }, [sub]);
    if (!plan) return null;
    else {
        return (
            <>
                <Card className='mui-box' style={{ backgroundColor: '#F0F0F0', padding: '1rem' }}>
                    <CardContent>
                        <Header text={`${plan?.name}`} />
                        <Subheader text={`${sub?.status.toUpperCase()}`} />
                        <Header
                            text={`${userDoc.credits ? userDoc.credits : 0} credits remaining`}
                        />
                    </CardContent>
                    <CardActions>
                        <PassedButton onClick={goToCredits} text='Add Credits' />
                        <PassedButton onClick={goToSubscription} text='Manage Subscription' />
                    </CardActions>
                </Card>
            </>
        );
    }
}

export default DashboardSubscription;
