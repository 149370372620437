import React from 'react';
import { Box, Chip } from '@mui/material';

function ScanStats({ wordCount }) {
    return (
        <Box className='scanOptionsStats' style={{ justifyContent: 'flex-end' }}>
            <Box style={{ display: 'flex' }}>
                <Chip
                    label={'Words: ' + wordCount}
                    color='primary'
                    variant='outlined'
                    className='scanChips'
                />
            </Box>
        </Box>
    );
}

export default ScanStats;
