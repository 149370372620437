import AccountCard from '../../components/AccountCard/AccountCard';
import Header from '../../components/MUI/Header';
import './Account.css';
import '../../components/MUI/MuiStyles.css';
import { Box, Button, Typography } from '@mui/material';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import Subscriptions from '../Subscriptions/Subscriptions';
import AccountModal from '../../components/AccountModal/AccountModal';
import { useState } from 'react';
import { DeleteOutlined, Email } from '@mui/icons-material';

const Account = () => {
    const { userDoc } = useStoreState(AppStore);
    const { accountModalOpen } = useStoreState(AppStore);
    const [context, setContext] = useState('');
    const toggleModal = () => {
        AppStore.update((s) => {
            s.accountModalOpen = !accountModalOpen;
        });
    };
    if (!userDoc?.institutionId) {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Header text='Account Details' />
                <Box className='user-info'>
                    <AccountCard />

                    {userDoc.active === true ? <Subscriptions /> : ''}
                    <Header text='Danger Zone' />
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                        gap={2}
                    >
                        {/* <Button
                            variant='text'
                            color='primary'
                            style={{
                                textTransform: 'none',
                                fontSize: '0.75rem',
                                margin: '1rem 0',
                            }}
                            onClick={() => {
                                setContext('password');
                                toggleModal();
                            }}
                        >
                            Change Password
                        </Button> */}
                        <Button
                            variant='text'
                            color='primary'
                            style={{
                                textTransform: 'none',
                                fontSize: '0.75rem',
                                margin: '1rem 0',
                            }}
                            onClick={() => {
                                setContext('email');
                                toggleModal();
                            }}
                        >
                            <Email fontSize='small' />
                            &nbsp;Change Email
                        </Button>
                        <Button
                            variant='text'
                            color='tertiary'
                            style={{
                                textTransform: 'none',
                                fontSize: '0.75rem',
                                margin: '1rem 0',
                            }}
                            onClick={() => {
                                setContext('delete');
                                toggleModal();
                            }}
                        >
                            <DeleteOutlined fontSize='small' /> Delete Account
                        </Button>
                    </Box>

                    <AccountModal
                        isOpen={accountModalOpen}
                        onClose={toggleModal}
                        context={context}
                    />
                </Box>
            </Box>
        );
    } else {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Header text='Account Details' />
                <Typography
                    variant='body1'
                    style={{ margin: '1rem 0', textAlign: 'center', width: 400 }}
                >
                    Your account is managed by your institution. Please contact your administrator
                    to make changes to your account.
                </Typography>
            </Box>
        );
    }
};

export default Account;
