import React from 'react';
import { Box } from '@mui/material';

import AffiliateHowTo from '../../components/AffiliateHowTo/AffiliateHowTo';
import AffiliateRules from '../../components/AffiliateRules/AffiliateRules';

import './Affiliate.css';
import Header from '../../components/MUI/Header';
import Subheader from '../../components/MUI/Subheader';
import '../../components/MUI/MuiStyles.css';
import AffiliateRobot from '../../assets/images/AffiliateRobot.gif';
import useMediaQuery from '@mui/material/useMediaQuery';
import PassedButton from '../../components/MUI/PassedButton';
export default function Affiliate() {
    const desktop = useMediaQuery('(max-width:1200px)');
    const onClick = () => {
        // GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'affiliate_button_click',
        });

        window.open('https://passed-ai.getrewardful.com/signup', '_blank');
    };
    return (
        <Box className='mui-box'>
            <Header text='Become an affiliate, earn a recurring 20% commission' />
            <Subheader
                text={`You'll be earning lifelong commissions on every subscription linked to you`}
            />
            <PassedButton onClick={onClick} text='Sign up for our affiliate program' />
            <AffiliateHowTo />
            <AffiliateRules />
            {!desktop && (
                <Box style={{ left: 250, top: 120, position: 'fixed', zIndex: 0 }}>
                    <img className='robot-app' src={AffiliateRobot} alt='Robot'></img>
                </Box>
            )}
        </Box>
    );
}
