import {
    getAuth,
    isSignInWithEmailLink,
    onAuthStateChanged,
    signInWithEmailLink,
} from 'firebase/auth';
import { useStoreState } from 'pullstate';
import { useEffect, useState } from 'react';
import { AppStore } from './stores/AppStore';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import {
    getSubscriptions,
    getSetUserDoc,
    getPlans,
    getSubCollectionData,
    getScanHistory,
} from './utils/getData';
import { createTheme, ThemeProvider } from '@mui/material';
import TagManager from 'react-gtm-module';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Subscriptions from './Pages/Subscriptions/Subscriptions';
import Onboarding from './Pages/Onboarding/Onboarding';
import Affiliate from './Pages/Affiliate/Affiliate';
import Help from './Pages/Help/Help';
import PrivateRoutes from './utils/PrivateRoutes';
import Navigation from './components/Navigation/Navigation';
import Loading from './components/Loading/Loading';
import ScanNow from './Pages/ScanNow/ScanNow';
import Account from './Pages/Account/Account';
import Credits from './Pages/Credits/Credits';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainPage from './components/MainPage/MainPage';
import Extension from './components/Extension/Extension';
import Goodbye from './Pages/Goodbye/Goodbye';
import ScanHistory from './Pages/ScanHistory/ScanHistory';
import ScanResults from './Pages/ScanResults/ScanResults';
import Students from './Pages/Students/Students';
import Courses from './Pages/Courses/Courses';
// import Assignments from './Pages/Assignments/Assignments';
import { LMSStore } from './stores/LMSStore';
import { auth } from './firebase';
import Admin from './Pages/Admin';
import useUserPresence from './utils/Presence';

// GTM
const tagManagerArgs = {
    gtmId: 'GTM-M94H4PS',
};

TagManager.initialize(tagManagerArgs);
window.document.title = 'Passed.ai';

window.dataLayer.push({
    event: 'pageview',
});

function App() {
    const { userDoc, appBarHeight, drawerWidth } = useStoreState(AppStore);
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    const mobile = useMediaQuery('(max-width:650px)');
    const location = window.location.pathname;
    //GTM

    //create mui theme
    const passedTheme = createTheme({
        palette: {
            primary: {
                main: '#1f2641',
            },
            secondary: {
                main: '#FFC107',
            },
            tertiary: {
                main: '#e55934',
            },
            quaternary: {
                main: '#30bced',
            },
            success: {
                main: '#4FB477',
                contrastText: '#fff',
            },
            danger: {
                main: '#D32F2F',
                contrastText: '#fff',
            },
        },
    });

    useEffect(() => {
        if (user) getSubscriptions(user);
    }, [user]);

    useEffect(() => {
        onAuthStateChanged(getAuth(), async (fbUser) => {
            if (fbUser) {
                setUser(fbUser);
                AppStore.update((s) => {
                    s.user = fbUser;
                });
                //gets available plans from firestore and sets them in the store
                getPlans();
                //gets user subscriptions from firestore and sets them in the store
                getSubscriptions(fbUser); //change into own useeffect
                //gets user data from firestore and sets it in the store
                getSetUserDoc(fbUser);
                // console.log('userHERE', user);
                getSubCollectionData(fbUser.uid, 'students', LMSStore);
                getSubCollectionData(fbUser.uid, 'courses', LMSStore);
                getSubCollectionData(fbUser.uid, 'assignments', LMSStore);
                getSubCollectionData(fbUser.uid, 'memberships', AppStore);
                getScanHistory(fbUser);
                //small delay just to show the loading screen
                //can be removed if we don't want to show the loading screen
                setTimeout(() => {
                    setReady(true);
                }, 300);
            }
            //small delay just to show the loading screen
            //can be removed if we don't want to show the loading screen
            setTimeout(() => {
                setReady(true);
            }, 300);
        });
    }, [user]);

    useEffect(() => {
        function getClientReferenceId() {
            return (
                (window.Rewardful && window.Rewardful.referral) ||
                'checkout_' + new Date().getTime()
            );
        }
        AppStore.update((s) => {
            s.clientReferenceId = getClientReferenceId();
        });
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }, []);
    useUserPresence('dashboard'); // Set user context to 'dashboard'

    if (!ready) {
        return <Loading />;
    } else if (location === '/ext-scan' || location === '/ext-scans/:id') {
        return (
            <ThemeProvider theme={passedTheme}>
                <Router>
                    <Routes>
                        <Route path='/ext-scan' element={<ScanNow />} />
                        <Route path='/ext-scans/:id' element={<ScanResults />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        );
    } else if (location === '/ext-help') {
        return (
            <ThemeProvider theme={passedTheme}>
                <Help />
            </ThemeProvider>
        );
    } else if (user && ready && !userDoc.ready) {
        return (
            <ThemeProvider theme={passedTheme}>
                <Onboarding />
            </ThemeProvider>
        );
    } else {
        return (
            <>
                <ThemeProvider theme={passedTheme}>
                    <Router>
                        {user && <Navigation />}

                        <div
                            className='app_content'
                            //conditionals in style are to accomodate login page
                            style={{
                                maxWidth: '100vw',
                                height: '100vh',
                                marginLeft:
                                    !mobile && userDoc.uid
                                        ? `${drawerWidth}px`
                                        : !mobile && !userDoc.uid
                                        ? 0
                                        : mobile
                                        ? 0
                                        : `${drawerWidth}px`,
                                paddingTop:
                                    !mobile && userDoc.uid
                                        ? `calc(${appBarHeight}px + 24px)`
                                        : !mobile && !userDoc.uid
                                        ? 0
                                        : mobile
                                        ? 0
                                        : `calc(${appBarHeight}px + 24px)`,
                            }}
                        >
                            <Routes>
                                <Route element={<PrivateRoutes user={user} />}>
                                    <Route
                                        path='/dashboard'
                                        element={<MainPage content={<Home />} />}
                                    />
                                    <Route
                                        path='/subscribe'
                                        element={<MainPage content={<Subscriptions />} />}
                                    />
                                    <Route
                                        path='/account'
                                        element={<MainPage content={<Account />} />}
                                    />
                                    <Route path='/help' element={<MainPage content={<Help />} />} />
                                    <Route
                                        path='/affiliate'
                                        element={<MainPage content={<Affiliate />} />}
                                    />
                                    <Route
                                        path='/scan'
                                        element={<MainPage content={<ScanNow />} />}
                                    />
                                    <Route
                                        path='/scans'
                                        element={<MainPage content={<ScanHistory />} />}
                                    />
                                    {/* <Route
                                        path='/assignments'
                                        element={<MainPage content={<Assignments />} />}
                                    /> */}
                                    <Route
                                        path='/courses'
                                        element={<MainPage content={<Courses />} />}
                                    />
                                    <Route
                                        path='/students'
                                        element={<MainPage content={<Students />} />}
                                    />
                                    <Route
                                        path='/scans/:id'
                                        exact
                                        element={<MainPage content={<ScanResults />} />}
                                    />
                                    <Route
                                        path='/credits'
                                        element={<MainPage content={<Credits />} />}
                                    />
                                    <Route
                                        path='/extension'
                                        element={<MainPage content={<Extension />} />}
                                    />
                                    <Route
                                        path='/'
                                        element={
                                            <Navigate
                                                to='/dashboard'
                                                element={<MainPage content={<Home />} />}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path='/login'
                                    element={<MainPage content={<Login user={user} />} />}
                                />
                                <Route
                                    path='/goodbye'
                                    element={<MainPage content={<Goodbye />} />}
                                />
                                <Route path='/admin' element={<MainPage content={<Admin />} />} />
                            </Routes>
                        </div>
                    </Router>
                </ThemeProvider>
            </>
        );
    }
}

export default App;
