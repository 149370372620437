//pullstate store

import { Store } from 'pullstate';

export const CurrentScan = new Store({
    content: '',
    title: '',
    words: [],
    wordCount: 0,
    credits: 0,
    aiCheck: true,
    plagiarismCheck: false,
    results: null,
    scanDisabled: false,
    inputDisabled: false,
    scanType: null,
    error: false,
    activeTab: 'results',
    loading: false,
    mode: 'ai',
    highlightOn: false,
    model: 1,
});
