import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    updateDoc,
    deleteDoc,
    or,
    addDoc,
    writeBatch,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { db } from '../firebase';
import { AppStore } from '../stores/AppStore';
import { OnboardingStore } from '../stores/OnboardingStore';
import logOperation from './LogReads';

export const updateCredits = async (user) => {
    try {
        const docRef = doc(db, 'users', user);
        const docSnap = await getDoc(docRef);
        // console.log('docRef', docRef);
        // console.log('docSnap', docSnap);
        let credits;

        if (docSnap.exists()) {
            // console.log('user doc found');
            const data = docSnap.data();
            // console.log('data', data);
            credits = data.credits;
        } else {
            console.error('user doc not found');
        }

        AppStore.update((s) => {
            s.userDoc = { ...s.userDoc, credits: credits };
        });
    } catch (error) {
        // Handle error here
        console.error(error);
    }
};
export const getSetUserDoc = async (user) => {
    try {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        await logOperation(user, 'read', `users/${user.uid}`);
        // console.log('userid', user.uid);
        let userDoc;
        let data;
        if (docSnap.exists()) {
            data = docSnap.data();
            const id = docSnap.id;
            userDoc = { ...data, id };
            if (data?.institutionId) {
                const institutionRef = doc(db, 'institutions', data.institutionId);
                const institutionSnap = await getDoc(institutionRef);
                const institutionData = institutionSnap.data();
                userDoc.institutionInfo = institutionData;
                AppStore.update((s) => {
                    s.user = user;
                    s.userDoc = { ...s.userDoc, institutionInfo: institutionData, ready: true };
                });
            }
        } else {
            const userData = {
                name: user.displayName,
                email: user.email,
                photoUrl: user.photoURL,
                active: false,
                uid: user.uid,
            };

            await setDoc(docRef, userData);
            userDoc = userData;
        }
        AppStore.update((s) => {
            s.user = user;
            s.userDoc = { ...userDoc, ready: data.institutionId || data.ready ? true : false };
        });
    } catch (error) {
        // Handle error here
        console.error(error);
    }
};

export const activeSub = async (user) => {
    try {
        const subRef = collection(db, 'users', user.uid, 'subscriptions');
        const activeSubRef = query(subRef, where('status', '==', 'active'));
        const activeSubSnap = await getDocs(activeSubRef);
        await logOperation(user, 'read', `users/${user.uid}/subscriptions`);
        // console.log('activeSubSnap', activeSubSnap);
        if (activeSubSnap.docs.length > 0) {
            // console.log('active subscriptions found');
            return true;
        } else {
            return false;
        }
    } catch (error) {
        // Handle error here
        console.error('error', error);
    }
};

export const getSubscriptions = async (user) => {
    // console.log('getting subscriptions');
    try {
        const subRef = collection(db, 'users', user.uid, 'subscriptions');
        const activeSubRef = query(
            subRef,
            or(where('status', '==', 'active'), where('status', '==', 'trialing'))
        );
        const activeSubSnap = await getDocs(activeSubRef);
        await logOperation(user, 'read', `users/${user.uid}/subscriptions`);
        const subsArray = [];
        // console.log('activeSubSnap', activeSubSnap);
        if (activeSubSnap.docs.length > 0) {
            // console.log('active subscriptions found');
            activeSubSnap.docs.forEach((doc) => {
                const data = doc.data();
                const id = doc.id;
                subsArray.push({ ...data, id });
            });
            //set user to active
            const docRef = doc(db, 'users', user.uid);
            await updateDoc(docRef, {
                active: true,
            });

            AppStore.update((s) => {
                s.userDoc = {
                    ...s.userDoc,
                    subscriptions: subsArray,
                    active: true,
                };
            });
            OnboardingStore.update((s) => {
                s.step = 3;
            });
        } else {
            // console.log('no active subscriptions found');
            AppStore.update((s) => {
                s.userDoc = {
                    ...s.userDoc,
                    subscriptions: [],
                    active: false,
                };
            });
            const docRef = doc(db, 'users', user.uid);
            await updateDoc(docRef, {
                active: false,
            });
        }
    } catch (error) {
        // Handle error here
        console.error('getSubscriptions error', error);
    }
};

export const getPlans = async () => {
    try {
        const plansQuery = query(collection(db, 'plans'), where('active', '==', true));
        const querySnapshot = await getDocs(plansQuery);
        const plansArray = [];

        for (const doc of querySnapshot.docs) {
            const priceSnap = await getDocs(collection(doc.ref, 'prices'));
            const prices = [];

            for (const priceDoc of priceSnap.docs) {
                const pData = priceDoc.data();
                const pId = priceDoc.id;
                if (pData.active) {
                    prices.push({ ...pData, id: pId });
                }
            }

            plansArray.push({ ...doc.data(), id: doc.id, prices });
        }

        AppStore.update((s) => {
            s.plans = plansArray;
        });
    } catch (error) {
        // Handle error here
        console.error(error);
    }
};

export const getPlan = async (id) => {
    //get doc matching id
    const planRef = doc(db, 'plans', id);
    const planSnap = await getDoc(planRef);
    const planData = planSnap.data();
    const planId = planSnap.id;
    const plan = { ...planData, id: planId };

    //get prices
    const priceRef = collection(db, 'plans', id, 'prices');
    const priceSnap = await getDocs(priceRef);
    const prices = [];
    priceSnap.docs.forEach((doc) => {
        const data = doc.data();
        const id = doc.id;
        prices.push({ ...data, id });
    });
    plan.prices = prices;

    return plan;
};

export const getSub = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const subscriptionQuery = query(
        collection(userRef, 'subscriptions'),
        or(where('status', '==', 'active'), where('status', '==', 'trialing'))
    );

    const querySnapshot = await getDocs(subscriptionQuery);

    if (!querySnapshot.empty) {
        const activeSubscription = querySnapshot.docs[0];
        return activeSubscription.id;
    } else {
        console.log('No active subscriptions found for this user.');
    }
};

export const getSubCollectionData = async (userId, subCollectionName, store) => {
    // console.log('getting subcollection data', userId, subCollectionName);
    const userRef = doc(db, 'users', userId);
    const subCollectionQuery = query(collection(userRef, subCollectionName));
    const querySnapshot = await getDocs(subCollectionQuery);
    await logOperation(userId, 'read', `users/${userId}/${subCollectionName}`);
    const data = [];

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const id = doc.id;
            data.push({ ...docData, id });
        });
    } else {
        // console.log(`No ${subCollectionName} found for this user.`);
    }
    // console.log('data', data);
    store.update((s) => {
        s[subCollectionName] = data;
    });

    return data;
};
export const addDocToSubCollection = async (userId, subCollectionName, data, store) => {
    const userRef = doc(db, 'users', userId);
    const subCollectionRef = collection(userRef, subCollectionName);

    // Check if a document with the same identifier already exists, but only for 'students' and 'courses' subcollections
    if (['courses', 'students'].includes(subCollectionName)) {
        const queryRef = query(subCollectionRef, where('identifier', '==', data.identifier));
        const querySnapshot = await getDocs(queryRef);

        if (!querySnapshot.empty) {
            throw new Error('A document with the same identifier already exists.');
        }
    }

    const docRef = await addDoc(subCollectionRef, data);
    console.log('docRef', docRef);

    store.update((s) => {
        const updatedData = [...s[subCollectionName], { ...data, id: docRef.id }];
        return { ...s, [subCollectionName]: updatedData };
    });

    return docRef.id;
};

export const editDocInSubCollection = async (userId, subCollectionName, docId, newData, store) => {
    const userRef = doc(db, 'users', userId);
    const docRef = doc(userRef, subCollectionName, docId);
    await updateDoc(docRef, newData);

    store.update((s) => {
        const updatedData = s[subCollectionName].map((item) =>
            item.id === docId ? { ...item, ...newData } : item
        );
        return { ...s, [subCollectionName]: updatedData };
    });

    if (subCollectionName === 'courses' || subCollectionName === 'students') {
        getSubCollectionData(userId, 'scans');
    }
};
export const deleteDocFromSubCollection = async (userId, subCollectionName, docId, store) => {
    const userRef = doc(db, 'users', userId);
    const docRef = doc(userRef, subCollectionName, docId);
    await deleteDoc(docRef);

    if (store) {
        store.update((s) => {
            const updatedData = s[subCollectionName].filter((item) => item.id !== docId);
            return { ...s, [subCollectionName]: updatedData };
        });
    }
};

export const shareAssignment = async (userId, subCollectionName, docId, store, students) => {
    // console.log('sharing assignment', userId, subCollectionName, docId, store, students);
    const docRef = doc(db, 'users', userId, subCollectionName, docId);
    // console.log('docRef', docRef);
    const studentsRef = collection(docRef, 'students');

    // Create a new batch using writeBatch instead of db.batch
    const batch = writeBatch(db);

    students.forEach((student) => {
        const studentRef = doc(studentsRef, student);
        batch.set(studentRef, { email: student });
    });

    try {
        await batch.commit();
        await updateDoc(docRef, { shared: true });
    } catch (error) {
        console.error('Error sharing assignment:', error);
        throw error;
    }

    if (store) {
        store.update((s) => {
            const updatedData = s[subCollectionName].filter((item) => item.id !== docId);
            return { ...s, [subCollectionName]: updatedData };
        });
    }
};
export const deleteFromFirestore = async (userId) => {
    const userDocRef = doc(db, 'users', userId);

    const deleteCollectionDocs = async (collectionPath) => {
        const collectionRef = collection(db, collectionPath);
        const docsSnapshot = await getDocs(collectionRef);
        docsSnapshot.forEach((doc) => deleteDoc(doc.ref));

        if (!docsSnapshot.empty) {
            docsSnapshot.forEach((doc) => deleteDoc(doc.ref));
        }
    };

    // List all the subcollections
    const collectionsToDelete = [
        'checkout_sessions',
        'marketingEmails',
        'payments',
        'subscriptions',
    ];

    for (const collectionName of collectionsToDelete) {
        await deleteCollectionDocs(`users/${userId}/${collectionName}`);
    }

    // Delete the main user document after all its subcollections have been deleted
    await deleteDoc(userDocRef);
};

export const deleteFromAuth = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        try {
            await user.delete();
            console.log('User deleted from Firebase Auth');
        } catch (error) {
            console.error('Error deleting user from Firebase Auth:', error);
        }
    } else {
        console.log('No user currently logged in');
    }
};

export const deleteFromFireBase = async (userId) => {
    const storage = getStorage();

    const userRef = ref(storage, `users/${userId}`);
    await deleteObject(userRef);
};

export const getScanHistory = async (user) => {
    const scansRef = collection(db, 'users', user.uid, 'scans');
    const qF = query(scansRef, where('fullScanData', '!=', null));
    const prevScans = [];
    const querySnapshotF = await getDocs(qF);
    querySnapshotF.forEach((doc) => {
        const data = doc.data();
        const aiScore = data?.fullScanData?.scan_results?.aiResults?.fake;
        const originalScore = data?.fullScanData?.scan_results?.aiResults?.real;
        const plagiarismScore = data?.fullScanData?.scan_results?.plagResults?.documentScore;
        const scanType = data?.fullScanData?.scan_results?.scan?.scan_type;
        prevScans.push({
            ...data,
            id: doc.id,
            aiScore: aiScore,
            originalScore: originalScore,
            plagiarismScore: scanType === 1 ? 'N/A' : plagiarismScore,
        });
    });

    //filter out duplicates by doc id
    const seen = new Set();
    const filteredArr = prevScans.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
    });

    return filteredArr;
};

export const getOperations = async () => {
    const operationsRef = collection(db, 'operationLogs');
    const q = query(operationsRef);
    const operations = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        operations.push({ ...data, id: doc.id });
    });

    return operations;
};
