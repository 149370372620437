import React from 'react';
import { Done, PriorityHigh } from '@mui/icons-material';
import { Alert, Button, Card, CardContent } from '@mui/material';
import Link from '@mui/material/Link';
import InstallExtension from '../InstallExtension/InstallExtension';
import './WelcomeChecklist.css';
import { getSetUserDoc } from '../../utils/getData';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Box } from '@mui/system';

const WelcomeChecklist = ({ installed, subscribed }) => {
    const { user, userDoc } = useStoreState(AppStore);
    const [showError, setShowError] = React.useState(false);
    return (
        <div>
            <Card className={`checklist-card ${installed ? 'green' : ''}`} maxwidth={{ xs: '80%' }}>
                <CardContent className='checklist-card-content'>
                    <Link
                        href='https://chrome.google.com/webstore/detail/chatgpt-ai-detector-for-e/nmkodlgnddendacmkpghbnjmaknnegfo'
                        underline='none'
                        target='_blank'
                        className={installed ? 'checklist-item-disabled' : ''}
                    >
                        <div className='checklist-card-container'>
                            <div className='checklist-text'>
                                {installed
                                    ? `Extension installed!`
                                    : 'Click here to install the extension'}
                            </div>
                            {installed ? <Done /> : <PriorityHigh />}
                        </div>
                        {!installed && <InstallExtension />}
                    </Link>
                    {!userDoc?.extensionInstalled && (
                        <Box>
                            <Button
                                className='passed-btn centered'
                                variant='contained'
                                color='primary'
                                style={{ margin: '2rem auto 0' }}
                                onClick={() => {
                                    getDoc(doc(db, 'users', user.uid)).then((doc) => {
                                        if (doc.exists()) {
                                            const userData = doc.data();
                                            if (userData?.extensionInstalled) {
                                                AppStore.update((s) => {
                                                    s.userDoc = {
                                                        ...s.userDoc,
                                                        extensionInstalled: true,
                                                    };
                                                });
                                            } else {
                                                setShowError(true);
                                            }
                                        }
                                    });
                                    getSetUserDoc(user);
                                }}
                            >
                                I've installed the extension
                            </Button>
                            <div
                                className='error-message'
                                style={{
                                    display: showError === false ? 'none' : 'inline-block',
                                    margin: '1rem auto',
                                }}
                            >
                                <Alert severity='error'>
                                    We could not detect that you have installed the extension.
                                    Please make sure you install and log into the extension with the
                                    same Google account as you have used here (
                                    <strong>{user.email}</strong>
                                    ). If you require assistance, please use the chat bubble in the
                                    bottom right to contact us.
                                </Alert>
                            </div>
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Card className={`checklist-card ${subscribed ? 'green' : ''}`}>
                <Link
                    href='/subscribe'
                    underline='none'
                    className={subscribed ? 'checklist-item-disabled' : ''}
                >
                    <CardContent className='checklist-card-content'>
                        <div className='checklist-card-container'>
                            <div className='checklist-text'>
                                {subscribed ? `You're subscribed!` : 'Subscribe to a plan'}
                            </div>
                            {subscribed ? <Done /> : <PriorityHigh />}
                        </div>
                    </CardContent>
                </Link>
            </Card>
        </div>
    );
};

export default WelcomeChecklist;
