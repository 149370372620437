import { Box } from '@mui/material';
import './InstallExtension.css';

const InstallExtension = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <p>
                Install the Passed.ai Chrome Extension to start scanning your Google Docs documents
                now. Please make sure you are using Google Chrome, install the extension from the
                Chrome Web Store, and log into the extension with the same account you registered
                with here.
            </p>
            <p>Here is a video showing it step-by-step:</p>
            <Box>
                <iframe
                    width='100%'
                    height='100%'
                    src='https://www.youtube.com/embed/xH-5dVMwF4o'
                    title='YouTube video player'
                    frameBorder='0'
                    allowFullScreen
                ></iframe>
            </Box>
        </Box>
    );
};

export default InstallExtension;
