import React, { useState } from 'react';
import { Backdrop, Modal, Fade, Box, Typography, Button, TextField, Divider } from '@mui/material';
import './AccountModal.css';
import {
    getSub,
    deleteFromFirestore,
    deleteFromAuth,
    deleteFromFireBase,
} from '../../utils/getData';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { db, logout } from '../../../src/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Grid } from '@mui/material';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Delete, Save } from '@mui/icons-material';
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    getAuth,
    reauthenticateWithCredential,
    sendEmailVerification,
    signInWithPopup,
    updateEmail,
} from 'firebase/auth';
import GoogleIcon from '@mui/icons-material/Google';
import { doc, updateDoc } from 'firebase/firestore';

const AccountModal = ({ isOpen, onClose, context }) => {
    const { userDoc } = useStoreState(AppStore);
    const [newValue, setNewValue] = React.useState('');
    const [newValueConfirmation, setNewValueConfirmation] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('error');
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [reauthenticated, setReauthenticated] = useState(false);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };
    const handleDelete = async () => {
        setLoading(true);
        const subId = (await getSub(userDoc.uid)) || null;

        if (subId) {
            try {
                await fetch(
                    'https://us-central1-passedai-development.cloudfunctions.net/deleteStripeSubscription',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ customerId: userDoc.stripeId }),
                    }
                );

                handleSnackbarOpen('Subscription deleted', 'success');
            } catch (error) {
                console.error('Failed to delete subscription: ', error);
            }
        }
        deleteFromFirestore(userDoc.uid);
        deleteFromAuth();
        deleteFromFireBase(userDoc.uid);
        setLoading(false);
        logout();
    };
    const reauthenticateWithEmailPassword = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(newValue, password);

        try {
            await reauthenticateWithCredential(user, credential);
            console.log('Re-authentication successful.');
            handleSnackbarOpen('Re-authentication successful.', 'success');
            setReauthenticated(true);
            return true;
        } catch (error) {
            console.error('Re-authentication error:', error);
            handleSnackbarOpen('Re-authentication error:' + error, 'error');
            return false;
        }
    };
    const reauthenticateWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        try {
            await signInWithPopup(auth, provider);
            console.log('Re-authentication with Google successful.');
            setReauthenticated(true);
            return true;
        } catch (error) {
            console.error('Re-authentication with Google error:', error);
            return false;
        }
    };

    const handleUpdateEmail = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!reauthenticated && password) {
            reauthenticateWithEmailPassword();
            return;
        }
        if (!user) {
            console.log('No user logged in');
            return;
        }
        if (reauthenticated && newValue === newValueConfirmation) {
            try {
                // Assuming re-authentication has already been handled by this point
                await updateEmail(user, newValue);
                //update user doc in users collection

                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, {
                    email: newValue,
                    previousEmail: user.email,
                });

                updateStripeUserEmail();
                sendEmailVerification();
                console.log('Email update successful.');
                // Handle post-update logic here (e.g., show success message, sign out the user, etc.)
                handleSnackbarOpen('Please check your inbox to confirm', 'success');
                logout();
            } catch (error) {
                console.error('Email update error:', error);
                // Handle errors (e.g., show error message)
                handleSnackbarOpen('Error updating email address ' + error, 'error');
            }
        }
    };
    const updateStripeUserEmail = async () => {
        const functions = getFunctions(); // Initialize Firebase Functions
        const updateUserEmailAndStripe = httpsCallable(functions, 'updateUserEmailAndStripe');

        try {
            await updateUserEmailAndStripe({
                newValue: newValue,
                stripeCustomerId: userDoc.stripeId,
            });
        } catch (error) {
            handleSnackbarOpen('Email update failed', 'error');
        } finally {
            handleSnackbarOpen('Email updated', 'success');
            AppStore.update((s) => {
                s.accountModalOpen = false;
            });
        }
    };

    // const updateUserPassword = async () => {
    //     if (newValue !== newValueConfirmation) {
    //         handleSnackbarOpen('Passwords do not match', 'error');
    //         return;
    //     } else if (newValue.length < 6) {
    //         handleSnackbarOpen('Password must be at least 6 characters', 'error');
    //         return;
    //     } else {
    //         const functions = getFunctions(); // Initialize Firebase Functions
    //         const updateUserPassword = httpsCallable(functions, 'updateUserPassword');

    //         try {
    //             await updateUserPassword({ newValue: newValue });
    //         } catch (error) {
    //             handleSnackbarOpen('Password update failed', 'error');
    //         } finally {
    //             handleSnackbarOpen('Password updated', 'success');
    //             AppStore.update((s) => {
    //                 s.accountModalOpen = false;
    //             });
    //         }
    //     }
    // };
    return (
        <>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={isOpen}
                onClose={onClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isOpen}>
                    <Box className='modal-container'>
                        <Typography id='modal-title' variant='h6' component='h2'>
                            {context === 'email'
                                ? 'Change your email'
                                : context === 'password'
                                ? 'Change your password'
                                : 'Are you sure you want to delete your account?'}
                        </Typography>
                        <Typography id='modal-text'>
                            {context === 'email'
                                ? 'Enter your new email address. When you click save, you will be logged out and will need to log back in with your new email.'
                                : context === 'password'
                                ? 'Enter your new password'
                                : ` All of your information will be deleted. You can't undo this action.`}
                        </Typography>
                        {(context === 'email' || context === 'password') && (
                            <Box id='modal-inputs'>
                                <Grid container spacing={2} paddingY={2} sx={{ width: '100%' }}>
                                    <Grid item>
                                        <TextField
                                            variant='outlined'
                                            type={context === 'email' ? 'email' : 'password'}
                                            placeholder={
                                                context === 'email'
                                                    ? 'Enter your new email'
                                                    : 'Enter your new password'
                                            }
                                            onChange={(e) => setNewValue(e.target.value)}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type={context === 'email' ? 'email' : 'password'}
                                            placeholder={
                                                context === 'email'
                                                    ? 'Confirm your new email'
                                                    : 'Confirm your new password'
                                            }
                                            onChange={(e) =>
                                                setNewValueConfirmation(e.target.value)
                                            }
                                        />
                                    </Grid>
                                    {context === 'email' && (
                                        <Grid item>
                                            <Divider sx={{ my: 2 }} />
                                            <Typography sx={{ mb: 2 }}>
                                                You must re-authenticate to allow for an email
                                                address update.
                                            </Typography>
                                            <TextField
                                                type='password'
                                                placeholder='Enter your current password'
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <Divider sx={{ my: 2 }}>or</Divider>
                                            <Button
                                                startIcon={<GoogleIcon />}
                                                variant='contained'
                                                className='login_button'
                                                onClick={reauthenticateWithGoogle}
                                            >
                                                Re-authenticate with Google
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        )}
                        <Box className='modal-buttons'>
                            <Button
                                disabled={loading}
                                className='passed-btn'
                                variant='outlined'
                                color='quaternary'
                                style={{ margin: '1.5rem 0rem' }}
                                onClick={onClose}
                            >
                                Back
                            </Button>
                            <LoadingButton
                                disabled={loading || (!reauthenticated && !password)}
                                className='passed-btn'
                                variant='contained'
                                color='tertiary'
                                style={{ margin: '1.5rem 1rem', color: 'white' }}
                                onClick={
                                    context === 'delete'
                                        ? handleDelete
                                        : context === 'email'
                                        ? handleUpdateEmail
                                        : null
                                }
                                loading={loading}
                                loadingPosition='start'
                                startIcon={context === 'delete' ? <Delete /> : <Save />}
                            >
                                {context === 'delete' ? 'Delete' : 'Save'}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant='filled'
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default AccountModal;
