import IconMask6 from '../../assets/images/IconMask6.png';
import IconMaskGrey from '../../assets/images/IconMaskGrey.png';
import React from 'react';

let iconId = 0;

export default function PassedIcon({ iconPath, disabled }) {
    const clipPathId = `iconShape${iconId++}`;

    return (
        <svg width='24' height='24' viewBox='0 0 24 24'>
            <defs>
                <clipPath id={clipPathId}>
                    <path d={iconPath} />
                </clipPath>
            </defs>
            {!disabled && (
                <image href={IconMask6} width='24' height='24' clipPath={`url(#${clipPathId})`} />
            )}
            {disabled && (
                <image
                    href={IconMaskGrey}
                    width='24'
                    height='24'
                    clipPath={`url(#${clipPathId})`}
                />
            )}
        </svg>
    );
}
