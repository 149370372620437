import { Box, Chip, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { hsSubjects, collegeSubjects, gradSubjects } from '../../assets/data/subject';
import { React } from 'react';
import { AppStore } from '../../stores/AppStore';
import { useStoreState } from 'pullstate';

const SubjectList = () => {
    const { userDoc } = useStoreState(AppStore);
    const { level, subjects } = userDoc;

    const subjectList =
        level === 'high school'
            ? hsSubjects
            : level === 'undergraduate'
            ? collegeSubjects
            : gradSubjects;

    return (
        <TextField
            fullWidth
            select
            disabled={!level}
            SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                    if (selected.length === 0) {
                        return <em>Placeholder</em>;
                    } else
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        );
                },
            }}
            label='Subjects'
            id='subject-select-chip'
            value={subjects || []}
            onChange={(e) =>
                AppStore.update((s) => {
                    s.userDoc = {
                        ...s.userDoc,
                        subjects: e.target.value,
                    };
                })
            }
            input={<OutlinedInput id='subject-select-chip' label='Chip' />}
        >
            {subjectList.map((subject, i) => (
                <MenuItem key={i} value={subject}>
                    {subject}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SubjectList;
