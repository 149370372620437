import { useStoreState } from 'pullstate';
import { AppStore } from '../stores/AppStore';
import DashBoardSubscription from '../components/DashboardSubscription/DashBoardSubscription';
import Welcome from '../components/Welcome/Welcome';
import { Box } from '@mui/material';
import Header from '../components/MUI/Header';
import Subheader from '../components/MUI/Subheader';
import '../components/MUI/MuiStyles.css';
import ChillRobot from '../assets/images/ChillRobot.gif';
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = () => {
    // moved subscriptions fron useStoreState to userDoc.subscriptions because destructure returns undefined
    const { userDoc } = useStoreState(AppStore);
    const subscriptions = userDoc.subscriptions;

    const desktop = useMediaQuery('(max-width:1200px)');

    if (userDoc?.active && !userDoc?.institutionId) {
        return (
            <>
                <Box className='mui-box'>
                    <Header text='Dashboard' />
                    <Subheader
                        text={`Welcome back${userDoc.firstName && ', ' + userDoc.firstName}!`}
                    />
                    <Subheader text='Current Plan' />

                    {subscriptions?.map((sub, i) => (
                        <DashBoardSubscription sub={sub} key={i} />
                    ))}
                </Box>
                {!desktop && (
                    <Box
                        style={{
                            left: 250,
                            top: 120,
                            position: 'fixed',
                            zIndex: 2,
                        }}
                    >
                        <img className='robot-app' src={ChillRobot} alt='Robot'></img>
                    </Box>
                )}
            </>
        );
    } else if (userDoc?.active && userDoc?.institutionId) {
        return (
            <>
                <Box className='mui-box'>
                    <Header text='Dashboard' />
                    <Subheader text={userDoc?.institutionInfo?.name} />
                    {userDoc?.institutionInfo?.logo && (
                        <Box
                            className='mui-box'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItem: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                className='robot-app'
                                src={userDoc?.institutionInfo?.logo}
                                alt='Robot'
                            ></img>
                        </Box>
                    )}
                    <Subheader
                        text={
                            userDoc?.firstName
                                ? `Welcome back${userDoc.firstName && ', ' + userDoc.firstName}!`
                                : userDoc.displayName?.split(' ')[0]
                                ? `Welcome back${
                                      userDoc.displayName?.split(' ')[0] &&
                                      ', ' + userDoc.displayName?.split(' ')[0]
                                  }!`
                                : `Welcome back!`
                        }
                    />
                </Box>
            </>
        );
    } else {
        return <Welcome />;
    }
};

export default Home;
