import { useEffect } from 'react';
import { getDatabase, ref, onValue, onDisconnect, set } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Custom hook to update user presence
const useUserPresence = (context) => {
    useEffect(() => {
        const auth = getAuth();
        const database = getDatabase();

        // Monitor auth state changes to handle user sign-in and sign-out
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userId = user.uid;
                const presenceRef = ref(database, 'presence/dashboard/' + userId);
                const connectedRef = ref(database, '.info/connected');

                onValue(connectedRef, (snap) => {
                    if (snap.val() === true) {
                        const userStatus = {
                            status: 'online',
                            context: context, // Use the context passed to the hook
                        };
                        onDisconnect(presenceRef).remove();
                        set(presenceRef, userStatus);
                    }
                });
            }
        });
    }, [context]);
};

export default useUserPresence;
