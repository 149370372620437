import React from 'react';
import { Button } from '@mui/material';

function PassedButton(props) {
    return (
        <Button
            style={{
                backgroundColor: '#FCBA04',
                color: 'black',
                margin: '0.5rem',
                padding: '1rem',
                borderRadius: '10px',
                lineHeight: '1.25rem',
            }}
            variant='contained'
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    );
}

export default PassedButton;
