import { Alert, Box, Button, TextField } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { gDocWordCount, wordCounter } from '../../../utils/scanFns';
import './ScanInput.css';
import { useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import './ScanInput.css';

const ScanInput = () => {
    const textareaRef = useRef(null);
    const location = window.location.pathname;
    const { scanDisabled, inputDisabled, content, wordCount } = useStoreState(CurrentScan);
    const urlParams = new URLSearchParams(window.location.search);
    const fid = urlParams.get('fid');
    const id = urlParams.get('id');

    const handleInput = async (e) => {
        CurrentScan.update((s) => {
            s.content = e.target.value;
            s.words = e.target.value.split(' ');
            s.wordCount = wordCounter(e);
        });
    };

    useEffect(() => {
        if (fid) {
            const observer = new MutationObserver(() => {
                if (textareaRef.current) {
                    const textareaHeight = textareaRef.current.scrollHeight;
                    window.parent.postMessage(textareaHeight, '*');
                }
            });

            if (textareaRef.current) {
                observer.observe(textareaRef.current, { childList: true, subtree: true });
            }

            // Clean up the observer when the component unmounts.
            return () => {
                observer.disconnect();
            };
        }
    }, [fid]);

    useEffect(() => {
        if (location === '/ext-scan') {
            CurrentScan.update((s) => {
                s.inputDisabled = true;
            });
        }
        if (fid && id) {
            // get firebase doc matching id from gDocs collection if it exists
            getDoc(doc(db, 'users', fid, 'scans', id)).then((doc) => {
                if (doc.exists()) {
                    // console.log('Document data:', doc.data());
                    const docData = doc.data();
                    const content = docData.characters;
                    const words = content.split(' ');
                    if (content.length > 0) {
                        CurrentScan.update((s) => {
                            s.content = content;
                            s.words = words;
                            s.title = docData.title;
                            s.id = id;
                            s.wordCount = gDocWordCount(content);
                        });
                    }
                }
            });
        }
        // console.log(fid, id);
    }, [fid, id, location]);
    return (
        <Box>
            <TextField
                multiline
                ref={textareaRef}
                disabled={scanDisabled || inputDisabled}
                aria-label='empty textarea'
                placeholder='Enter text here...'
                className='contentBox'
                debounce={1000}
                minRows={20}
                value={content}
                onChange={(e) => handleInput(e)}
                maxRows={20}
                resize='none'
            />
            <Alert
                severity='success'
                sx={{ boxShadow: '1px 1px 2px grey', margin: '1rem 0' }}
                action={
                    <Button
                        href='https://passed.ai/multilanguage-ai-detection/'
                        target='_blank'
                        color='inherit'
                        size='small'
                    >
                        Learn More
                    </Button>
                }
            >
                Our AI scanner now supports 15 languages.
            </Alert>

            {wordCount < 50 && (
                <Alert
                    severity='info'
                    sx={{ backgroundColor: 'white', boxShadow: '1px 1px 2px grey' }}
                >
                    We require a minimum of 50 words to scan.
                </Alert>
            )}
        </Box>
    );
};

export default ScanInput;
