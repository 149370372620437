import { Grid, Box, Button, Alert } from '@mui/material';
import Scanner from '../../components/Scanner/Scanner';
import './ScanNow.css';
import Header from '../../components/MUI/Header';
import { React } from 'react';
import CreditsChip from '../../components/CreditsChip/CreditsChip';
import { useEffect, useState } from 'react';
import { GoogleAuthProvider, getAuth, signInWithCredential } from 'firebase/auth';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../stores/CurrentScan';
import { Replay } from '@mui/icons-material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const ScanNow = () => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(getAuth().currentUser || null);
    const [prevScan, setPrevScan] = useState(null);
    const { content, loading, error, errorMessage, errorCTA } = useStoreState(CurrentScan);
    const location = window.location.pathname;

    const urlParams = new URLSearchParams(window.location.search);
    const ftok = urlParams.get('ftok');
    const id = urlParams.get('id');

    useEffect(() => {
        if (currentUser) {
            return;
        } else {
            if (ftok) {
                const auth = getAuth();
                const credential = GoogleAuthProvider.credential(null, ftok);
                signInWithCredential(auth, credential)
                    .then((user) => {
                        // User is signed in.
                        setCurrentUser(user);
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        console.error(error);
                    });
            }
        }
    }, [ftok, currentUser]);
    useEffect(() => {
        // console.log(currentUser, id);
        if (id && currentUser) {
            // get firebase doc matching id from user's sub-collection 'scans' if it exists
            getDoc(doc(db, 'users', currentUser.uid, 'scans', id)).then((doc) => {
                if (doc.exists()) {
                    const docData = doc.data();
                    // console.log('Document data:', docData);
                    setPrevScan(docData);
                }
            });
        }
    }, [id, currentUser]);
    const resetScan = () => {
        CurrentScan.update((s) => {
            s.content = '';
            s.wordCount = 0;
            s.results = null;
            s.scanDisabled = false;
            s.scanId = null;
            s.error = false;
            s.credits = 0;
            s.title = '';
            s.course = '';
            s.student = '';
        });
    };

    const goToPrevious = () => {
        if (location === '/scan') {
            navigate(`/scans/${prevScan.id}`);
        } else {
            navigate(`/ext-scans/${prevScan.id}`);
        }
    };
    const handleErrorCTA = (cta) => {
        if (cta === 'Buy Credits') {
            navigate('/credits');
        } else if (cta === 'Contact Support') {
            //send email to hello@passed.ai

            window.open(
                `mailto:hello@passed.ai?subject=Passed.AI Scan Support Request&body=Please add as much detail about the issue you're experiencing as possible:`
            );
        } else {
        }
    };

    if (currentUser) {
        return (
            <>
                <Box sx={{ width: '100%' }}>
                    <Grid container className='scanHeader' sx={{ marginBottom: '2rem' }}>
                        <Grid item xs={12} md={6}>
                            <Header text={'Document Scanner'} />
                        </Grid>
                        <Grid item xs={12} md={6} className='chipHolder'>
                            {location === '/ext-scan' && <CreditsChip class='scanHeaderChip' />}
                            {content && location !== '/ext-scan' && (
                                <Button
                                    variant='contained'
                                    color='quaternary'
                                    className='scanHeaderButton'
                                    startIcon={<Replay />}
                                    onClick={() => {
                                        resetScan();
                                    }}
                                    disabled={loading || !content}
                                    sx={{ marginLeft: '1rem' }}
                                >
                                    Reset Scan
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    {prevScan?.fullScanData?.scan_results && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Alert
                                    severity='info'
                                    action={
                                        <Button color='inherit' onClick={goToPrevious}>
                                            View Previous
                                        </Button>
                                    }
                                    sx={{ marginBottom: '2rem' }}
                                >
                                    There is a previous scan of this document from{' '}
                                    {dayjs(
                                        prevScan?.fullScanData?.scan_results?.scan?.created_at
                                    ).format('MMM DD, YYYY hh:mma')}
                                    .{' '}
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    {error && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Alert
                                    severity='error'
                                    sx={{ marginBottom: '2rem' }}
                                    action={
                                        errorCTA && (
                                            <Button
                                                color='inherit'
                                                onClick={() => handleErrorCTA(errorCTA)}
                                            >
                                                {errorCTA}
                                            </Button>
                                        )
                                    }
                                >
                                    {errorMessage || 'An error occurred. Please try again.'}
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    <Scanner />
                </Box>
            </>
        );
    } else {
        return (
            <div>
                <h1>Not Authenticated</h1>
                <p>Please reload this page.</p>
            </div>
        );
    }
};

export default ScanNow;
