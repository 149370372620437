//pullstate store

import { Store } from 'pullstate';

export const AppStore = new Store({
    user: null,
    userDoc: {
        ready: false,
        useMyData: true,
    },
    drawerWidth: 280,
    appBarHeight: 64,
    plans: null,
    clientReferenceId: null,
    highlightOn: false,
    memberships: [],
    accountModalOpen: false,
});
