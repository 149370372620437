import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HighScore from './HighScore';

import './ResultsBar.css';
import { Typography } from '@mui/material';
const ResultsBar = ({ val, shownVal, label }) => {
    const [showDetails, setShowDetails] = useState(false);

    const fullClass = val > 99.5 ? 'full' : '';

    const valExplainer =
        label === 'Flow Score' ? (
            <Typography variant='p' sx={{ textAlign: 'left' }}>
                {val > 60
                    ? `This document shows a probable natural writing flow. We determine this by looking for natural patterns in the writing, including enough actions and time to create, edit, and delete the content.`
                    : val > 40
                    ? `This document raises some questions based on some activities (or lack thereof) we're seeing the the data. We recommend that you view the full document audit and the replay of the document's creation.`
                    : `Something appears to be off as we're not seeing enough activity in the data to generate the text of this document. We recommend that you view the full document audit and the replay of the document's creation.`}
            </Typography>
        ) : label === 'Overall AI Confidence' ? (
            <>
                <Typography variant='p' sx={{ textAlign: 'left' }}>
                    This score reflects our AI's confidence in predicting that the content scanned
                    was produced by any popular AI tool such as ChatGPT, GPT-4 Turbo, Gemini
                    Advanced, Claude 2, Mistral, Llama 2 etc. A score of 90% original and 10% AI
                    should be thought of as "We are 90% confident that this content was created by a
                    human" and NOT that 90% of the article is Human and 10% AI.
                </Typography>
                <br />
                <br />
                <Typography variant='p' sx={{ textAlign: 'left' }}>
                    {val > 80 ? (
                        <>
                            We have high confidence that parts of this document were created by AI.
                            You can use the Text Highlighter to see which parts of the document we
                            think were created by AI.
                            <HighScore />
                        </>
                    ) : val > 30 ? (
                        `We have medium confidence that parts of this document were created by AI. You can use the Text Highlighter to see which parts of the document we think were created by AI.`
                    ) : (
                        `We have low confidence that parts of this document were created by AI. You can use the Text Highlighter to see which parts of the document we think were created by AI.`
                    )}
                </Typography>

                <br />
                <br />

                <Typography>
                    <strong>Text Highlighting</strong>: We break down the content scanned into
                    "chunks" that are scanned independently. Because these are smaller chunks, the
                    AI Confidence Score may be less accurate than the overall confidence score.{' '}
                    <em>The overall score is not the average of the individual chunks</em>.
                </Typography>
            </>
        ) : label === 'Plagiarism Score' ? (
            <Typography variant='p' sx={{ textAlign: 'left' }}>
                The Plagiarized value is the percent of your content that contains plagiarism
                according to our scan. Refer to the table below to view the specific websites we
                found the plagiarism on. <strong>Please note:</strong> We do not scan small amounts
                of text or common phrases that would be common to many websites.
            </Typography>
        ) : (
            ''
        );

    const color =
        label === 'Flow Score'
            ? val > 60
                ? '#4FB477'
                : val > 40
                ? '#ffab00'
                : '#e55934'
            : label === 'Overall AI Confidence'
            ? val > 80
                ? '#e55934'
                : val > 30
                ? '#ffab00'
                : '#4FB477'
            : '#1f2641';
    return (
        <>
            <div className='results-bar-label'>
                {label}
                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className='results-bar-expand-button'
                >
                    {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </button>
            </div>
            <button onClick={() => setShowDetails(!showDetails)} className='results-bar-container'>
                <div
                    className={`${fullClass} results-bar`}
                    style={{ width: val + '%', backgroundColor: color }}
                >
                    {val > 50 && (
                        <div className='results-bar-val inside'>
                            {shownVal}

                            <HelpOutlineOutlinedIcon
                                sx={{
                                    fontSize: '2rem',
                                    height: '100%',
                                    marginLeft: '0.5rem',
                                    verticalAlign: 'top',
                                }}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${fullClass} results-bar`}
                    style={{ width: 1 - val + '%', backgroundColor: '#fff' }}
                >
                    {val <= 50 && <div className='results-bar-val outside'>{shownVal}</div>}
                </div>
            </button>

            <div className={`results-bar-details ${showDetails ? 'expanded' : ''}`}>
                {valExplainer}
            </div>
        </>
    );
};

export default ResultsBar;
