const hsSubjects = [
    'Art',
    'Biology',
    'Calculus',
    'Chemistry',
    'Computer Science',
    'Earth Science',
    'Economics',
    'English Language Arts',
    'Foreign Language',
    'Geography',
    'Health Education',
    'History',
    'Music',
    'Physics',
    'Physical Education',
    'Psychology',
    'Social Studies',
    'Statistics',
];
const collegeSubjects = [
    'Accounting',
    'Anthropology',
    'Art History',
    'Biology',
    'Business Administration',
    'Chemistry',
    'Communication Studies',
    'Computer Science',
    'Economics',
    'Education',
    'Engineering',
    'English Language and Literature',
    'Environmental Science/Studies',
    'History',
    'International Relations',
    'Mathematics',
    'Music',
    'Nursing',
    'Philosophy',
    'Physics',
    'Political Science',
    'Psychology',
    'Sociology',
];

const gradSubjects = [
    'Business Administration (MBA)',
    'Computer Science',
    'Counseling',
    'Education',
    'Engineering',
    'English Language and Literature',
    'Environmental Science/Studies',
    'Finance',
    'Healthcare Administration',
    'History',
    'Information Technology',
    'International Relations',
    'Law',
    'Marketing',
    'Mathematics',
    'Medicine',
    'Nursing',
    'Philosophy',
    'Physics',
    'Political Science',
    'Psychology',
    'Public Administration',
    'Social Work',
    'Sociology',
];

export { hsSubjects, collegeSubjects, gradSubjects };
