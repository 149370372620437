import { collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { createNewUser, db } from '../firebase';
import { Box, FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useStoreState } from 'pullstate';
import { AppStore } from '../stores/AppStore';
import { useNavigate } from 'react-router-dom';
// import { getOperations } from '../utils/getData';
const Admin = () => {
    const navigate = useNavigate();
    const { userDoc } = useStoreState(AppStore);
    const [institutions, setInstitutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedInstitution, setSelectedInstitution] = useState('');
    const [invitee, setInvitee] = useState({
        name: '',
        email: '',
        credits: 200,
    });
    // const [operations, setOperations] = useState([]);
    //get all institutions from firestore collection
    const getInstitutions = async () => {
        const institutionsQuery = query(collection(db, 'institutions'));
        try {
            const institutionsSnapshot = await getDocs(institutionsQuery);
            const institutionsList = institutionsSnapshot.docs.map((doc) => doc.data());
            setInstitutions(institutionsList);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getInstitutions();
        // getOperations().then((res) => {
        //     console.log(res);
        //     setOperations(res);
        //     const countByUserId = res.reduce((acc, { userId }) => {
        //         acc[userId] = (acc[userId] || 0) + 1;
        //         return acc;
        //     }, {});

        //     console.log(countByUserId);
        // });
    }, []);

    useEffect(() => {
        console.log(selectedInstitution);
    }, [selectedInstitution]);

    const inviteUser = async () => {
        setLoading(true);
        await createNewUser({
            email: invitee.email,
            password: 'abcd1234',
            displayName: invitee.name,
            credits: invitee.credits,
            institutionId: selectedInstitution.institutionId,
            institutionName: selectedInstitution.name,
            institutionLogo: selectedInstitution.logo,
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    if (!userDoc.superDuperAdmin) navigate('/'); //redirect to home if not superDuperAdmin
    else
        return (
            <Box>
                <Box
                    sx={{
                        minWidth: '90%',
                        width: '90%',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                >
                    <FormControl fullWidth sx={{ width: 300 }}>
                        <InputLabel id='institution-select'>Select Institution</InputLabel>
                        <Select
                            labelId='institution-select'
                            id='institution-select'
                            value={selectedInstitution}
                            label='Select Institution'
                            onChange={(e) => setSelectedInstitution(e.target.value)}
                        >
                            {institutions.map((institution, i) => (
                                <MenuItem key={i} value={institution}>
                                    {institution.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ width: 300 }}>
                        <TextField
                            id='invitee-name'
                            label='Invitee Name'
                            variant='standard'
                            value={invitee.name}
                            onChange={(e) => setInvitee({ ...invitee, name: e.target.value })}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ width: 300 }}>
                        <TextField
                            id='invitee-email'
                            label='Invitee Email'
                            variant='standard'
                            value={invitee.email}
                            onChange={(e) => setInvitee({ ...invitee, email: e.target.value })}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ width: 300 }}>
                        <Input
                            id='invitee-credits'
                            value={invitee.credits}
                            type='number'
                            onChange={(e) => setInvitee({ ...invitee, credits: e.target.value })}
                        />
                    </FormControl>
                    <LoadingButton loading={loading} variant='contained' onClick={inviteUser}>
                        Invite
                    </LoadingButton>
                </Box>
                {/* <Box>
                    <Box>
                        <h3>Operations</h3>
                        <Box>
                            {operations.map((operation, i) => (
                                <Box key={i}>
                                    <h4>{operation.operationType}</h4>
                                    <p>{operation.userId}</p>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box> */}
            </Box>
        );
};

export default Admin;
