import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

/**
 * Logs an operation to Firestore.
 *
 * @param {string} userId - The ID of the user performing the operation.
 * @param {string} operationType - The type of operation (e.g., 'read', 'write').
 * @param {string} documentPath - The path of the document involved in the operation.
 */
const logOperation = async (userId, operationType, documentPath) => {
    try {
        await addDoc(collection(db, 'operationLogs'), {
            userId: typeof userId === 'string' ? userId : userId.uid,
            operationType,
            documentPath,
            timestamp: serverTimestamp(), // Ensures the timestamp is set by the server for consistency
            source: 'dashboard',
        });
        // console.log('Operation logged successfully');
    } catch (error) {
        console.error('Error logging operation: ', error, documentPath);
    }
};

export default logOperation;
