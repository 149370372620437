import { Autocomplete, Grid, TextField } from '@mui/material';
import './ScanHeader.css';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { useState } from 'react';
import { LMSStore } from '../../../stores/LMSStore';

const ScanHeader = () => {
    const { title, loading } = useStoreState(CurrentScan);
    const [helperTextC, setHelperTextC] = useState('Choose existing or type new.');
    const [helperTextS, setHelperTextS] = useState('Choose existing or type new.');
    // const location = window.location.pathname;
    // const ext = location === '/ext-scan';
    const { courses, students } = useStoreState(LMSStore);
    // const history = useNavigate();
    return (
        <Grid
            container
            className='ScanHeader'
            spacing={1}
            sx={{
                marginBottom: '2rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    label='Title (optional)'
                    height='3rem'
                    fullWidth={true}
                    sx={{ width: '100%', backgroundColor: 'white' }}
                    style={{ borderRadius: '10px' }}
                    value={title}
                    onChange={(e) => {
                        CurrentScan.update((s) => {
                            s.title = e.target.value;
                        });
                    }}
                    disabled={loading}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}
            >
                <Autocomplete
                    disablePortal
                    id='combo-box-demo'
                    options={courses?.map((s) => s.identifier) || []}
                    sx={{ width: '100%', backgroundColor: 'white' }}
                    renderInput={(params) => <TextField {...params} label='Course (optional)' />}
                    onChange={(event, value) => {
                        CurrentScan.update((s) => {
                            s.course = value;
                        });
                    }}
                    onInputChange={(event, newInputValue) => {
                        CurrentScan.update((s) => {
                            s.course = newInputValue;
                        });

                        // If the new input value doesn't exist in the course identifiers, change the helper text
                        if (!courses?.map((s) => s.identifier).includes(newInputValue)) {
                            setHelperTextC('Creating course ' + newInputValue);
                        } else {
                            setHelperTextC(' ');
                        }
                        if (newInputValue === '') {
                            setHelperTextC('Choose existing or type new.');
                        }
                    }}
                    freeSolo
                    disabled={loading}
                />
                <small className='helper-text'>{helperTextC}</small>
            </Grid>
            <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
            >
                <Autocomplete
                    disablePortal
                    id='combo-box-demo'
                    options={students?.map((s) => s.identifier) || []}
                    sx={{ width: '100%', backgroundColor: 'white' }}
                    renderInput={(params) => <TextField {...params} label='Student (optional)' />}
                    onChange={(event, value) => {
                        CurrentScan.update((s) => {
                            s.student = value;
                        });
                    }}
                    onInputChange={(event, newInputValue) => {
                        CurrentScan.update((s) => {
                            s.student = newInputValue;
                        });
                        // If the new input value doesn't exist in the course identifiers, change the helper text
                        if (!students?.map((s) => s.identifier).includes(newInputValue)) {
                            setHelperTextS('Creating student ' + newInputValue);
                        } else {
                            setHelperTextS(' ');
                        }
                        if (newInputValue === '') {
                            setHelperTextS('Choose existing or type new.');
                        }
                    }}
                    freeSolo
                    disabled={loading}
                />
                <small className='helper-text'>{helperTextS}</small>
            </Grid>
        </Grid>
    );
};

export default ScanHeader;
