import React from 'react';
import './Legend.css';

const Legend = () => {
    const generateGradient = () => {
        const redColor = 'rgba(211, 47, 47,';
        const greenColor = 'rgba(79, 180, 119,';
        const transitionPoint = 0.4;

        const colorStops = Array.from({ length: 11 }, (_, i) => {
            const position = i / 10;
            const opacity =
                position < transitionPoint
                    ? 1 - position / transitionPoint
                    : (position - transitionPoint) / (1 - transitionPoint);
            const color =
                position < transitionPoint
                    ? `${greenColor} ${opacity})`
                    : `${redColor} ${opacity})`;
            return `${color} ${position * 100}%`;
        }).join(', ');

        return `linear-gradient(to right, ${colorStops})`;
    };

    return (
        <div className='legend'>
            <div className='labels'>
                <span>0%</span>
                <span>Overall AI Confidence</span>
                <span>100%</span>
            </div>
            <div className='gradient' style={{ background: generateGradient() }} />
        </div>
    );
};

export default Legend;
