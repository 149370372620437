import React from 'react';
import { Button, Box } from '@mui/material';
import { OnboardingStore } from '../../stores/OnboardingStore';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

function Footer({ disabled, nextStep, previousStep, backButton }) {
    const { userDoc } = useStoreState(OnboardingStore);
    const confirm = async () => {
        try {
            const docRef = doc(db, 'users', userDoc.uid);
            const userData = { ...userDoc, ready: true };
            AppStore.update((s) => {
                s.userDoc = userData;
            });
            await setDoc(docRef, userData, { merge: true });
        } catch (error) {
            // Handle error here
            console.error(error);
        }
        window.location.reload();
    };

    return (
        <Box className='onboarding_step_footer'>
            <Button
                disabled={disabled}
                variant='contained'
                className='onboarding_button disabled'
                onClick={() => {
                    if (!nextStep) {
                        confirm();
                    } else {
                        OnboardingStore.update((s) => {
                            s.step = nextStep;
                        });
                    }
                }}
            >
                {nextStep ? 'Next' : 'Confirm'}
            </Button>
            {backButton && (
                <Button
                    variant='text'
                    className='onboarding_button prev'
                    onClick={() => {
                        OnboardingStore.update((s) => {
                            s.step = previousStep;
                        });
                    }}
                >
                    Go Back
                </Button>
            )}
        </Box>
    );
}

export default Footer;
