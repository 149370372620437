import { useStoreState } from 'pullstate';
import { Box, ToggleButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentScan } from '../../../stores/CurrentScan';

const HighlightOnButton = () => {
    const { highlightOn } = useStoreState(CurrentScan);
    return (
        <Box className='Highlight-box'>
            <ToggleButton
                value='check'
                selected={highlightOn}
                onChange={() => {
                    CurrentScan.update((s) => {
                        s.highlightOn = !s.highlightOn;
                    });
                }}
            >
                {highlightOn ? <CheckIcon /> : <CloseIcon />} &nbsp; Highlight AI
            </ToggleButton>
        </Box>
    );
};

export default HighlightOnButton;
