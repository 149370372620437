import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline, Delete, Visibility } from '@mui/icons-material';
import { CurrentScan } from '../../stores/CurrentScan';
import { deleteDocFromSubCollection, getScanHistory } from '../../utils/getData';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import Header from '../../components/MUI/Header';
import './ScanHistory.css';

const ScanHistory = () => {
    // inside your component
    const history = useNavigate();
    const { user } = useStoreState(AppStore);
    const [scans, setScans] = useState([]);
    const [scanAgain, setScanAgain] = useState(false);
    const options = {
        selectableRows: 'none',
        elevation: 0,
        sortOrder: {
            name: 'lastUpdated',
            direction: 'desc',
        },
    };
    const columns = [
        {
            name: 'lastUpdated',
            label: 'Last Updated',
            options: {
                customBodyRender: (value) => {
                    return dayjs(value).format('MM/DD/YYYY, h:mm A');
                },
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } }),
            },
        },
        {
            name: 'flowScore',
            label: 'Flow Score',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value ? value.toFixed(2) : 'N/A';
                },
            },
        },
        {
            name: 'aiScore',
            label: 'AI Confidence',
            options: {
                customBodyRender: (value) => {
                    return value ? (value * 100).toFixed(2) + '%' : 'N/A';
                },
            },
        },
        {
            name: 'plagiarismScore',
            label: 'Plagiarism Confidence',
            options: {
                customBodyRender: (value) => {
                    return value > 0 ? value.toFixed(2) + '%' : value === 0 ? 0 + '%' : 'N/A';
                },
            },
        },
        {
            name: 'student',
            label: 'Student',
        },
        {
            name: 'course',
            label: 'Course',
        },
        {
            name: 'id',
            label: 'View Results',
            options: {
                customBodyRender: (value) => {
                    CurrentScan.update((s) => {
                        s.scanId = value;
                    });
                    return (
                        <IconButton
                            color='primary'
                            aria-label='View Results'
                            onClick={() => goToResults(value)}
                        >
                            <Visibility />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'Delete',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label='Delete'
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this scan?')) {
                                    deleteDocFromSubCollection(user.uid, 'scans', value);
                                    setScanAgain(!scanAgain);
                                }
                            }}
                            color='error'
                        >
                            <Delete />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const goToResults = (id) => {
        const scan = scans.find((scan) => scan.id === id);
        // console.log('go to results', scan.id);
        CurrentScan.update((s) => {
            s.results = scan.fullScanData ? scan.fullScanData : scan.quickScanData;
            s.scanDisabled = false;
        });
        history(`/scans/${scan.id}`);
    };

    useEffect(() => {
        setScanAgain(!scanAgain);

        if (user) getScanHistory(user).then((scans) => setScans(scans));
    }, [user, scanAgain]);

    // const shareReport = () => {
    //     console.log('share report');
    // };
    return (
        <Box sx={{ width: '100%' }}>
            <Grid
                container
                spacing={2}
                className='scanHeader'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                    width: '100%',
                }}
            >
                <Grid item xs={12} md={6}>
                    <Header text={'Scan History'} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    gap={2}
                >
                    <Grid item xs={12} md={8}></Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant='contained'
                            onClick={() => history('/scan')}
                            color='secondary'
                            startIcon={<AddCircleOutline />}
                            sx={{ width: '100%' }}
                        >
                            New Scan
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ marginBottom: 2 }}>
                        How to use
                    </Typography>
                    <p>
                        This is a history of your previous scans. You can view the results of each,
                        or delete those that you no longer need.
                    </p>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 5 }}>
                <Grid item xs={12}>
                    {scans && (
                        <MUIDataTable
                            // title={'Previous Scans'}
                            data={scans}
                            columns={columns}
                            options={options}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ScanHistory;
