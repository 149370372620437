import { Card, Typography, Box, Button } from '@mui/material';
import { React } from 'react';
import ExtensionIcon from '@mui/icons-material/Extension';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Extension.css';

function Extension() {
    const mobile = useMediaQuery('(max-width:650px)');

    return (
        <Card
            sx={{
                padding: '0.5rem 1rem',
                borderRadius: '10px',
                fontWeight: 'light',
                // backgroundColor: '#1F2641', // dark blue
                backgroundColor: 'rgba(48, 188, 237, 0.3)', // light blue
                width: mobile ? '100%' : '70%',
            }}
        >
            <Box className='snackbar-close-box'>
                <Typography
                    className='snackbar-text'
                    component='div'
                    sx={{
                        m: 1,
                    }}
                >
                    Install the extension to enjoy full access to Passed AI
                </Typography>
            </Box>
            <Box>
                <Box className='snackbar-footer' sx={{ flexDirection: mobile ? 'column' : 'row' }}>
                    <Button
                        className='snackbar-button'
                        sx={{
                            backgroundColor: '#30BCED',
                            color: 'black',
                        }}
                        href='https://chrome.google.com/webstore/detail/chatgpt-ai-detector-for-e/nmkodlgnddendacmkpghbnjmaknnegfo'
                        target='_blank'
                        rel='noopener noreferrer'
                        startIcon={<ExtensionIcon />}
                    >
                        Get Extension
                    </Button>
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 5 }}
                >
                    <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/R_0eELtAnTw?si=Mc40hekUTsgldVAX'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerpolicy='strict-origin-when-cross-origin'
                        allowfullscreen
                    ></iframe>
                </Box>
            </Box>
        </Card>
    );
}

export default Extension;
