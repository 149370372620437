import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useStoreState } from 'pullstate';
import { OnboardingStore } from '../../../stores/OnboardingStore';

import Level from '../../../assets/images/level-icon.png';
import Subjects from '../../../assets/images/subjects-icon.png';
import Institution from '../../../assets/images/school-icon.png';
import CheckRobot from '../../../assets/images/CheckRobot.gif';

import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';
import RobotBox from '../../../components/Onboarding/RobotBox';
import OnboardingTopGrid from '../../../components/Onboarding/OnboardingTopGrid';
import OnboardingBottomGrid from '../../../components/Onboarding/OnboardingBottomGrid';
import Footer from '../../../components/Onboarding/Footer';

const Step6 = () => {
    const { userDoc } = useStoreState(OnboardingStore);
    // console.log('userDocstep6', userDoc);
    const { firstName, lastName, level, subjects, institution } = userDoc;
    const title = 'Confirmation';
    const subTitle = ` Double-check, triple the fun! Confirm your details and let's get started.`;

    return (
        <Box maxWidth={{ xs: '80%', md: '50rem' }} className='step_6_container'>
            <Box className='onboarding_step step_one active'>
                <Box className='onboarding_step3_container'>
                    <Box className='onboarding_step_six'>
                        <OnboardingHeader title={title} subtitle={subTitle} step={3} />
                        <Typography
                            className='onboarding_text light'
                            fontSize={{ xs: '1.05rem', md: '2rem' }}
                            mb={{ xs: '1.5rem', md: '1rem' }}
                            mt={{ xs: '0.5rem', md: '0.5rem' }}
                            fontWeight='600'
                        >
                            Here's what you've shared with us, <br /> {firstName} {lastName}:
                        </Typography>

                        <Box className='onboarding_step6_container'>
                            <Box className='robot_div step6'>
                                <RobotBox robot={CheckRobot} classProp={'robot_div step6'} />
                            </Box>

                            <Grid container spacing={2} columns={12} className='onboarding_grid'>
                                <OnboardingTopGrid src={Level} />
                                <OnboardingTopGrid src={Subjects} />
                                <OnboardingTopGrid src={Institution} />

                                <OnboardingBottomGrid srcName={level} />
                                <OnboardingBottomGrid srcName={subjects.join(', ')} />
                                <OnboardingBottomGrid
                                    srcName={institution ? `${institution}` : ''}
                                />
                            </Grid>
                            <Box
                                display={{ xs: 'none', md: 'flex' }}
                                className='step_empty_div step6'
                            />
                        </Box>
                    </Box>
                </Box>
                <Footer previousStep={4} backButton={true} />
            </Box>
        </Box>
    );
};

export default Step6;
