import { Logout, Settings } from '@mui/icons-material';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useStoreState } from 'pullstate';
import { logout } from '../../../firebase';
import { AppStore } from '../../../stores/AppStore';
import { NavLink } from 'react-router-dom';
import PassedIcon from '../../PassedIcon/PassedIcon';

const DrawerFooter = ({ width, toggleSidebar }) => {
    const { user } = useStoreState(AppStore);
    const { userDoc } = useStoreState(AppStore);
    return (
        <List className='drawer-footer' style={{ width: width }}>
            <Divider />
            <NavLink className='side-nav-link' to='/extension' onClick={toggleSidebar}>
                <ListItem>
                    <ListItemIcon>
                        <PassedIcon iconPath='M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z' />
                    </ListItemIcon>
                    <ListItemText primary='Get the Extension' />
                </ListItem>
            </NavLink>
            <Divider />
            <ListItem>
                <ListItemText
                    className='user-email'
                    primary={user?.email}
                    sx={{ fontSize: '0.9rem' }}
                />
            </ListItem>

            <NavLink className='side-nav-link' to='/account' onClick={toggleSidebar}>
                <ListItem
                    secondaryAction={
                        <IconButton edge='end' aria-label='delete'>
                            <Settings />
                        </IconButton>
                    }
                >
                    <ListItemIcon>
                        <img
                            src={
                                user?.photoURL ||
                                'https://lh3.googleusercontent.com/a/default-user=s96-c'
                            }
                            alt='user'
                            referrerPolicy='no-referrer'
                            className='footer__user__image'
                        />
                    </ListItemIcon>
                    <ListItemText className='user-email' primary='Manage Account' />
                </ListItem>
            </NavLink>
            {userDoc?.superDuperAdmin && process.env.NODE_ENV === 'development' && (
                <NavLink className='side-nav-link' to='/admin' onClick={toggleSidebar}>
                    <ListItem>
                        <ListItemIcon>
                            <PassedIcon iconPath='M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm7-7H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-1.75 9c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69 0-.23.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z' />
                        </ListItemIcon>
                        <ListItemText primary='Admin' />
                    </ListItem>
                </NavLink>
            )}
            <ListItemButton onClick={() => logout()}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </ListItemButton>
        </List>
    );
};

export default DrawerFooter;
