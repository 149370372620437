import ScanInput from './ScanInput/ScanInput';
import Grid from '@mui/material/Unstable_Grid2';
import ScanOptions from './ScanOptions/ScanOptions';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../stores/CurrentScan';
import { useEffect } from 'react';
import { calcCredits } from '../../utils/scanFns';
import './Scanner.css';
import UploadDoc from './UploadDoc/UploadDoc';
import ScanHeader from './ScanHeader/ScanHeader';
import { Alert, Button, LinearProgress } from '@mui/material';
import { AppStore } from '../../stores/AppStore';

const Scanner = () => {
    const location = window.location.pathname;
    const { userDoc } = useStoreState(AppStore);
    const { wordCount, aiCheck, credits, plagiarismCheck, content, loading } =
        useStoreState(CurrentScan);
    useEffect(() => {
        // console.log(calcCredits({ wordCount, aiCheck, plagiarismCheck }));
        CurrentScan.update((s) => {
            s.credits = calcCredits({ wordCount, aiCheck, plagiarismCheck });
        });
    }, [wordCount, aiCheck, plagiarismCheck]);
    return (
        <Grid>
            <ScanHeader />
            {!content && location !== '/ext-scan' && <UploadDoc />}
            {content && <ScanOptions credits={credits} />}
            {loading && <LinearProgress color='secondary' />}
            {!loading && <div style={{ height: '4px' }} />}
            {credits > userDoc.credits && (
                <Alert
                    severity='error'
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    action={
                        <Button
                            href='https://app.passed.ai/credits?extension=true'
                            target='_blank'
                            color='inherit'
                        >
                            Buy Credits
                        </Button>
                    }
                >
                    You do not have enough credits to scan this text. Please add more credits.
                </Alert>
            )}

            {wordCount > 2999 && plagiarismCheck && (
                <Alert severity='warning' sx={{ marginTop: 1, marginBottom: 1 }}>
                    We only support up to 3000 words for plagiarism checks. This text is {wordCount}{' '}
                    words long.
                </Alert>
            )}
            <ScanInput />
        </Grid>
    );
};

export default Scanner;
