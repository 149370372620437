import { useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { LMSStore } from '../../stores/LMSStore';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import Header from '../../components/MUI/Header';
import { AddCircleOutline, Delete, Save } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';

import './Students.css';
import {
    addDocToSubCollection,
    deleteDocFromSubCollection,
    editDocInSubCollection,
} from '../../utils/getData';
import { LoadingButton } from '@mui/lab';

const Students = () => {
    const { user } = useStoreState(AppStore);
    const { students } = useStoreState(LMSStore);
    const [addOpen, setAddOpen] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const [loading, setLoading] = useState(false);
    const [editDocId, setEditDocId] = useState(null);

    const options = {
        selectableRows: 'none',
        elevation: 0,
    };
    const columns = [
        {
            name: 'identifier',
            label: 'Identifier',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } }),
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <button
                            className='dropdown-edit-button'
                            onClick={() => {
                                const rowData = students.find((student) => student.id === value);
                                setIdentifier(rowData.identifier);
                                setEditDocId(value);
                                setAddOpen(true);
                            }}
                        >
                            Edit
                        </button>
                    );
                },
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label='Delete'
                            onClick={() => {
                                if (
                                    window.confirm('Are you sure you want to delete this student?')
                                ) {
                                    deleteDocFromSubCollection(
                                        user.uid,
                                        'students',
                                        value,
                                        LMSStore
                                    );
                                }
                            }}
                            color='error'
                        >
                            <Delete />
                        </IconButton>
                    );
                },
            },
        },
    ];
    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: '/students',
                title: 'Students',
            },
        });
    }, [user]);

    const addDoc = () => {
        setLoading(true);
        if (editDocId) {
            // Edit existing document
            editDocInSubCollection(user.uid, 'students', editDocId, { identifier }, LMSStore)
                .then(() => {
                    setLoading(false);
                    setAddOpen(false);
                    setIdentifier('');
                    setEditDocId(null); // reset after editing
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        } else {
            // Add new document
            addDocToSubCollection(user.uid, 'students', { identifier }, LMSStore)
                .then(() => {
                    setLoading(false);
                    setAddOpen(false);
                    setIdentifier('');
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid
                container
                spacing={2}
                className='scanHeader'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                    width: '100%',
                }}
            >
                <Grid container item xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                        <Header text={'Students'} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                        {addOpen && (
                            <small className='helper-text no-indent'>
                                Enter an identifier for the student. This can be a student ID, name,
                                or any other identifier you want to use.
                            </small>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    gap={2}
                >
                    <Grid item xs={12} md={7}>
                        {addOpen && (
                            <>
                                <TextField
                                    value={identifier}
                                    placeholder='Student Identifier'
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setIdentifier(e.target.value)}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            onClick={() => (addOpen ? addDoc() : setAddOpen(!addOpen))}
                            color='secondary'
                            startIcon={addOpen ? <Save /> : <AddCircleOutline />}
                            sx={{ width: !addOpen ? '100%' : 'auto' }}
                            disabled={loading || (addOpen && !identifier)}
                            loadingPosition='start'
                        >
                            {addOpen ? 'Save' : loading ? 'Saving' : 'Add Student'}
                        </LoadingButton>
                        {addOpen && (
                            <Button
                                variant='outlined'
                                color='danger'
                                onClick={() => {
                                    setIdentifier('');
                                    setAddOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 5 }}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' sx={{ marginBottom: 2 }}>
                            Add a Student
                        </Typography>
                        <p>
                            Add new students by providing a unique identifier of your choice - a
                            name, email, or student ID. After inputting the identifier, hit "Add
                            Student". This identifier is now available for all future scans.
                        </p>
                        <p>
                            Need to make changes? No problem. You can easily edit or delete
                            identifiers. Just locate the student on your list and click "Edit" or
                            "Delete".
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {students && (
                        <MUIDataTable data={students} columns={columns} options={options} />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Students;
