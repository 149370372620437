import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import { activeSub } from '../../utils/getData';
import { OnboardingStore } from '../../stores/OnboardingStore';
import { useStoreState } from 'pullstate';
import './Onboarding.css';
import 'animate.css';
import { Button } from '@mui/material';
import { logout } from '../../firebase';

const Onboarding = () => {
    const { step, userDoc } = useStoreState(OnboardingStore);
    const [active, setActive] = useState(false);
    const auth = getAuth();
    //get user display name
    const currentUser = auth.currentUser;

    useEffect(() => {
        const activePromise = activeSub(currentUser);
        activePromise.then((res) => {
            setActive(res);
        });
    }, [currentUser]);

    useEffect(() => {
        const initialUserDoc = {
            email: currentUser?.email,
            displayName: currentUser?.displayName,
            photoURL: currentUser?.photoURL,
            firstName: currentUser?.displayName?.split(' ')[0],
            lastName: currentUser?.displayName?.split(' ')[1],
            phoneNumber: currentUser?.phoneNumber,
            uid: currentUser?.uid,
            level: '',
            subjects: [],
            institution: '',
            type: null,
            active: active,
        };
        OnboardingStore.update((s) => {
            s.userDoc = initialUserDoc;
        });
    }, [currentUser, active]);

    if (userDoc.subscriptions?.length > 0) {
        console.log('userDoc.subscriptions', userDoc.subscriptions);
        OnboardingStore.update((s) => {
            s.userDoc = {
                ...s.userDoc,
                step: 3,
            };
        });
    }
    // console.log('userDoc', userDoc);
    return (
        <div className='Onboarding'>
            {step === 1 && <Step1 />}
            {step === 2 && <Step2 />}
            {step === 3 && <Step3 />}
            {step === 4 && <Step4 />}
            {step === 5 && <Step5 />}
            {step === 6 && <Step6 />}
            <Button variant='text' className='onboarding_button prev' onClick={() => logout()}>
                Log out
            </Button>
        </div>
    );
};

export default Onboarding;
