import React from 'react';
import { Box } from '@mui/material';
import Subheader from '../MUI/Subheader';
import PassedList from '../MUI/PassedList';

export default function AffiliateRules() {
    const data = [
        'The affiliate will receive a 20% commission on a monthly basis, for every paying subscriber that is linked to them',
        'There is no end date to the commission. As long as the subscriber is paying, the affiliate will receive a commission',
        'If someone signed up through you but you did not get credit, we can rectify this for you, just email us',
        'Self-referrals are not allowed',
        'The affiliate can not pretend to be an employee and send out emails on our behalf',
    ];
    return (
        <Box className='affiliate-card'>
            <Subheader text='A few rules' />
            <PassedList data={data} />
        </Box>
    );
}
