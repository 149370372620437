import { useEffect, useRef, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { Button } from '@mui/material';
import { CurrentScan } from '../../stores/CurrentScan';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
// import { Send } from '@mui/icons-material';
import Results from '../../components/Results/Results';
import ResultsBar from '../../components/Results/ResultsBar/ResultsBar';
import Grid from '@mui/material/Unstable_Grid2';
import Header from '../../components/MUI/Header';
import LaunchIcon from '@mui/icons-material/Launch';
import dayjs from 'dayjs';
import './ScanResults.css';

const ScanResults = () => {
    const navigate = useNavigate();
    const resRef = useRef(null);
    const location = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const { scanId } = useStoreState(CurrentScan);
    const { user } = useStoreState(AppStore);
    const [data, setData] = useState(null);
    const [plagScan, setPlagScan] = useState(false);
    const rId = useParams().id;
    const id = urlParams.get('id') || rId;
    useEffect(() => {
        if (id) {
            const observer = new MutationObserver(() => {
                if (resRef.current) {
                    const resultsHeight = resRef.current.scrollHeight;
                    window.parent.postMessage(resultsHeight, '*');
                    console.log(resultsHeight);
                }
            });

            if (resRef.current) {
                observer.observe(resRef.current, { childList: true, subtree: true });
            }

            // Clean up the observer when the component unmounts.
            return () => {
                observer.disconnect();
            };
        }
    }, [id, resRef]);
    useEffect(() => {
        if (location === '/scan') {
            const getScan = async () => {
                // console.log(user, scanId);
                const scan = await getDoc(doc(db, 'users', user.uid, 'scans', scanId));
                // console.log(scan.data());
                setData(scan.data());
                if (scan.data().fullScanData.scan_results.scan.scan_type !== 1) {
                    setPlagScan(true);
                }
                if (scan.data().fullScanData?.scan_results?.aiResults) {
                    CurrentScan.update((s) => {
                        s.mode = 'ai';
                    });
                }
            };
            getScan();
        } else {
            const getScan = async () => {
                // console.log(user, id);
                const scan = await getDoc(doc(db, 'users', user.uid, 'scans', id));
                // console.log(scan.data());
                setData(scan.data());
                if (scan.data().fullScanData?.scan_results.scan.scan_type !== 1) {
                    setPlagScan(true);
                }
                if (scan.data().fullScanData?.scan_results?.aiResults) {
                    CurrentScan.update((s) => {
                        s.mode = 'ai';
                    });
                }
            };

            getScan();
        }
    }, [id, user, location, scanId]);
    const resetScan = () => {
        if (location.split('/')[1] === 'scans') {
            CurrentScan.update((s) => {
                s.content = '';
                s.wordCount = 0;
                s.results = null;
                s.scanDisabled = false;
                s.scanId = null;
                s.error = false;
                s.credits = 0;
                s.title = '';
                s.course = '';
                s.student = '';
            });
        } else {
            navigate(-1);
        }
    };
    if (data) {
        return (
            <Grid container ref={resRef}>
                <Grid xs={12}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignContent: 'center',
                        }}
                    >
                        <Grid xs={12} md={8}>
                            <Header text={data.title || 'Scan - ' + data?.lastUpdated} />
                            Last Scanned: {dayjs(data?.lastUpdated).format('MM/DD/YYYY hh:mm A')}
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {data?.url && (
                                <Grid xs={12} md={6} className='header-buttons'>
                                    <Button
                                        variant='outlined'
                                        startIcon={<LaunchIcon />}
                                        onClick={() => window.open(data.url, '_blank')}
                                    >
                                        Go To Doc
                                    </Button>
                                </Grid>
                            )}
                            {/* <Button variant='contained' endIcon={<Send />}>
                                    Share
                                </Button> */}
                            <Button
                                component={Link}
                                to='/scan'
                                variant='contained'
                                sx={{ marginLeft: 2 }}
                                color='secondary'
                                onClick={resetScan}
                            >
                                <Add />
                                New Scan
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12} sx={{ padding: '1rem 0' }}>
                    <Results data={data} plagScan={plagScan} />
                </Grid>
                {location.split('/')[1] !== 'ext-scans' && (
                    <Grid xs={12}>
                        {data?.flowScore && (
                            <ResultsBar
                                val={(data?.flowScore * 100).toFixed(2)}
                                label='Flow Score'
                                shownVal={data?.flowScore?.toFixed(2)}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return <h1>Loading...</h1>;
    }
};

export default ScanResults;
