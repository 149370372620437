import { Fragment, useState } from 'react';
import Legend from './Legend/Legend';
import './Results.css';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../stores/CurrentScan';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HighlightOnButton from './HighlightOnButton/HighlightOnButton';
import PlagiarismAccordions from './PlagPanel/PlagiarismAccordions/PlagiarismAccordions';
import ResultsBar from './ResultsBar/ResultsBar';

const Results = ({ data, plagScan }) => {
    const location = window.location.pathname.split('/')[1];
    const { mode, highlightOn } = useStoreState(CurrentScan);
    const highlightClass = highlightOn ? 'highlight' : '';
    const [value, setValue] = useState('ai');
    const fullScan = data?.fullScanData;
    const handleChange = (event, newValue) => {
        setValue(newValue);
        CurrentScan.update((s) => {
            s.mode = newValue;
        });
    };
    return (
        <Grid container className='Results-container'>
            <TabContext value={value}>
                <Grid container xs={12}>
                    <Grid item xs={12} md={8} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons='auto'
                            aria-label='lab API tabs example'
                        >
                            <Tab label='AI Results' value='ai' />
                            {plagScan && <Tab label='Plagiarism Results' value='plagiarism' />}
                        </TabList>
                    </Grid>
                    {data?.fullScanData?.scan_results?.aiResults && mode === 'ai' && (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <HighlightOnButton />
                        </Grid>
                    )}
                </Grid>
                <TabPanel value='ai' sx={{ padding: '24px 0', width: '100%' }}>
                    <Grid item xs={12}>
                        <Box className='Results' id='resultsContainer'>
                            {data?.fullScanData?.scan_results?.aiResults?.blocks?.map(
                                (block, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`${highlightClass} block ${
                                                block.result.fake > 0.75
                                                    ? 'red'
                                                    : block.result.fake > 0.4
                                                    ? 'pink'
                                                    : 'green'
                                            }`}
                                            style={{ '--strength': block.result.fake }}
                                        >
                                            {block.text.split('\n').map((line, j, arr) => {
                                                return (
                                                    <Fragment key={j}>
                                                        {i !== 0 && j === 0 && (
                                                            <div className='spacer' />
                                                        )}
                                                        <span className='line'>{line}</span>
                                                        {j !== arr.length - 1 && <br />}
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    );
                                }
                            )}
                        </Box>
                    </Grid>
                    {highlightOn && mode !== 'plagiarism' && (
                        <Grid item xs={12} sx={{ minHeight: '70px', overflow: 'visible' }}>
                            <Legend />
                        </Grid>
                    )}
                    {data?.fullScanData?.scan_results?.aiResults && mode === 'ai' && (
                        <Grid item xs={12}>
                            <div style={{ height: '1rem' }} />
                            <ResultsBar
                                val={(
                                    data?.fullScanData?.scan_results?.aiResults?.fake * 100
                                ).toFixed(2)}
                                label='Overall AI Confidence'
                                shownVal={
                                    (
                                        data?.fullScanData?.scan_results?.aiResults?.fake * 100
                                    ).toFixed(2) + '%'
                                }
                            />
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value='plagiarism' sx={{ padding: '24px 0', width: '100%' }}>
                    <Grid item xs={12}>
                        <Box className='Results' id='resultsContainer'>
                            {data?.fullScanData?.scan_results?.aiResults?.blocks?.map(
                                (block, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`block ${
                                                block.result.fake > 0.75
                                                    ? 'red'
                                                    : block.result.fake > 0.4
                                                    ? 'pink'
                                                    : 'green'
                                            }`}
                                            style={{ '--strength': block.result.fake }}
                                        >
                                            {block.text.split('\n').map((line, j, arr) => {
                                                return (
                                                    <Fragment key={j}>
                                                        {i !== 0 && j === 0 && (
                                                            <div className='spacer' />
                                                        )}
                                                        <span className='line'>{line}</span>
                                                        {j !== arr.length - 1 && <br />}
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    );
                                }
                            )}
                        </Box>
                    </Grid>
                    {plagScan && mode === 'plagiarism' && (
                        <Grid item xs={12}>
                            <div style={{ height: '1rem' }} />
                            <ResultsBar
                                val={(data?.fullScanData?.scan_results?.plagResults?.documentScore).toFixed(
                                    2
                                )}
                                label='Plagiarism Score'
                                shownVal={
                                    (data?.fullScanData?.scan_results?.plagResults?.documentScore).toFixed(
                                        2
                                    ) + '%'
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {location === 'ext-scans' && (
                            <Button
                                href={`https://app.passed.ai/scans/${data.id}`}
                                variant='contained'
                                color='secondary'
                                target='_blank'
                            >
                                View Full Results
                            </Button>
                        )}
                        {location !== 'ext-scans' && (
                            <PlagiarismAccordions results={fullScan.scan_results.plagResults} />
                        )}
                    </Grid>
                </TabPanel>
            </TabContext>
        </Grid>
    );
};

export default Results;
