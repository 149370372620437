import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Subheader from '../MUI/Subheader';

export default function AffiliateHowTo() {
    const data = [
        {
            avatar: <LooksOneIcon />,
            text: 'Click the button above to sign up for our affiliate program. You will receive a unique link from Rewardful.',
        },
        {
            avatar: <LooksTwoIcon />,
            text: 'Share your link with family, friends, and anyone else who wants to get a subscription at Passed.AI.',
        },
        {
            avatar: <Looks3Icon />,
            text: 'Every time someone subscribes with your link, you will get a monthly, 20% commission for as long as that person is a customer with us. ',
        },
    ];

    return (
        <Box className='affiliate-card' style={{ zIndex: 10 }}>
            <Subheader text='How this works' />
            <List sx={{ mb: 2 }}>
                {data?.map((item, i) => (
                    <ListItem key={i}>
                        <ListItemIcon>{item.avatar}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            {/* <PassedList data={data} /> */}
        </Box>
    );
}
