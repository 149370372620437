import { Button, Box, Typography } from '@mui/material';
import { useStoreState } from 'pullstate';
import Logo from '../../../assets/images/logo-full-dark.png';
import { OnboardingStore } from '../../../stores/OnboardingStore';
const Step1 = () => {
    const { userDoc } = useStoreState(OnboardingStore);
    const { firstName } = userDoc;

    return (
        <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
            <img src={Logo} alt='Passed.ai' className='onboarding_logo' />
            <Typography
                className='welcome animate__animated animate__fadeIn'
                fontSize={{ xs: '1.5rem', md: '2.5rem' }}
                mb={{ xs: '1rem', md: '2.5rem' }}
                mt={{ xs: '0.5rem', md: '1rem' }}
                fontWeight='bold'
            >
                Welcome{firstName && ', ' + firstName}!{' '}
            </Typography>

            {/* {firstName && (
                <p className='onboarding_text animate__animated animate__fadeIn animate__delay-2s'>
                    Let's jump in
                </p>
            )} */}
            {!firstName && (
                <p className='onboarding_text animate__animated animate__fadeIn animate__delay-2s'>
                    Before we get started, we'd like to confirm a few things.
                </p>
            )}

            <Button
                variant='contained'
                className='onboarding_button animate__animated animate__fadeIn animate__delay-2s'
                onClick={() => {
                    OnboardingStore.update((s) => {
                        userDoc.active ? (s.step = 2) : (s.step = 2);
                    });
                }}
            >
                Let's jump in
            </Button>
        </Box>
    );
};

export default Step1;
