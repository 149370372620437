import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Divider,
    Grid,
    Link,
    Tab,
    Typography,
} from '@mui/material';
import './PlagiarismAccordions.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';

const PlagiarismAccordions = ({ results }) => {
    const [value, setValue] = useState('phrases');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // console.log(results);
    return (
        <div className='PlagPanel__table'>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    aria-label='lab API tabs example'
                >
                    {results?.phraseResults && <Tab label='Phrase Matches' value='phrases' />}
                    {results?.websiteResults && <Tab label='Website Matches' value='websites' />}
                </TabList>
                <TabPanel value='websites' sx={{ padding: '24px 0' }}>
                    <Grid item xs={12}>
                        {results?.websiteResults.map((site, index) => {
                            const url = site?.website;
                            const score = site?.score;
                            let domain = new URL(url);
                            domain = domain.hostname.replace('www.', '');

                            return (
                                <Accordion key={index} elevation={0}>
                                    <AccordionSummary
                                        className='PlagPanel__table-row'
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography>{domain}</Typography>
                                        <Chip
                                            label={score.toFixed(0) + '%'}
                                            color='danger'
                                            variant='filled'
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {site?.results.map((res, i) => (
                                            <Box
                                                key={i}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    gap: '1rem',
                                                    marginBottom: '1rem',
                                                }}
                                            >
                                                <Chip
                                                    label={res.score.toFixed(0) + '%'}
                                                    color='danger'
                                                    variant='filled'
                                                />
                                                <Box>
                                                    <Typography>"{res?.phrase}"</Typography>
                                                    <br />
                                                    <a
                                                        className='siteLink'
                                                        href={site?.website}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        {site?.website}
                                                    </a>
                                                </Box>
                                                <Divider sx={{ mt: 2, mb: 2 }} />
                                            </Box>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Grid>
                </TabPanel>
                <TabPanel value='phrases' sx={{ padding: '24px 0' }}>
                    <Grid item xs={12}>
                        <Grid container paddingLeft={2} paddingRight={5} paddingY={2}>
                            <Grid item xs={12} md={8}>
                                <Typography sx={{ fontWeight: 500 }}>Plagiarized Phrase</Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography sx={{ fontWeight: 500, textAlign: 'center' }}>
                                    Matched Website
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography sx={{ fontWeight: 500, textAlign: 'center' }}>
                                    Avgerage Score
                                </Typography>
                            </Grid>
                        </Grid>
                        {results?.phraseResults.map((string, index) => {
                            const phrase = string?.phrase;
                            const matches = string?.matches;
                            //map matches to get the average score
                            const score = matches.reduce((acc, curr) => {
                                return acc + curr.score;
                            }, 0);
                            const avgScore = score / matches.length;

                            return (
                                <Accordion key={index} elevation={0}>
                                    <AccordionSummary
                                        className='PlagPanel__table-row'
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={8}>
                                                <Typography>"{phrase}"</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
                                                <Typography>{matches.length}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
                                                <Chip
                                                    label={avgScore?.toFixed(0) + '%'}
                                                    color='danger'
                                                    variant='filled'
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {matches.map((res, i) => {
                                            //limit to 10 results
                                            let displayUrl = res?.website
                                                .replace('https://', '')
                                                .replace('www.', '');
                                            displayUrl =
                                                displayUrl.length > 30
                                                    ? displayUrl.slice(0, 30) + '...'
                                                    : displayUrl;

                                            if (i > 9) return null;
                                            return (
                                                <Box
                                                    key={i}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        alignItems: 'center',
                                                        gap: '1rem',
                                                        marginBottom: '1rem',
                                                    }}
                                                >
                                                    <Chip
                                                        label={res.score.toFixed(0) + '%'}
                                                        color='danger'
                                                        variant='filled'
                                                    />
                                                    <Box>
                                                        <Link href={res?.website} target='_blank'>
                                                            {displayUrl}
                                                        </Link>
                                                    </Box>
                                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                                </Box>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Grid>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default PlagiarismAccordions;
