import { Box } from '@mui/material';
import { useStoreState } from 'pullstate';
import { OnboardingStore } from '../../../stores/OnboardingStore';
import WaveRobot from '../../../assets/images/WaveRobot.gif';
import RobotBox from '../../../components/Onboarding/RobotBox';
import Footer from '../../../components/Onboarding/Footer';
import OnboardingTextField from '../../../components/Onboarding/OnboardingTextField';
import EmptyRobotBox from '../../../components/Onboarding/EmptyRobotBox';
import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';

const Step3 = () => {
    const { userDoc } = useStoreState(OnboardingStore);
    const { firstName, lastName } = userDoc;
    const title = 'Account Set Up';
    const subTitle = `  Welcome aboard, ${firstName}! \nLet's confirm a few things before we get started.`;

    return (
        <>
            <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
                <Box textAlign={{ xs: 'center' }} className='onboarding_step3_container'>
                    <RobotBox robot={WaveRobot} />
                    <Box width={{ xs: '100%', md: '50rem' }} className='onboarding_step step_two'>
                        <OnboardingHeader title={title} subtitle={subTitle} step={1} />
                        <Box className='onboarding_step_content'>
                            <OnboardingTextField
                                id='firstName'
                                label='First Name'
                                placeholder='First Name'
                                defaultValue={firstName}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.firstName = e.target.value;
                                    });
                                }}
                            />

                            <OnboardingTextField
                                id='lastName'
                                label='Last Name'
                                placeholder='Last Name'
                                defaultValue={lastName}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.lastName = e.target.value;
                                    });
                                }}
                            />
                        </Box>
                    </Box>
                    <EmptyRobotBox />
                </Box>
                <Footer disabled={!firstName || !lastName} nextStep={4} backButton={false} />
            </Box>
        </>
    );
};

export default Step3;
