import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

function PassedList(props) {
    return (
        <List sx={{ mb: 2 }}>
            {props.data?.map((item, i) => (
                <ListItem key={i}>
                    <ListItemIcon>
                        <LabelImportantIcon />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
    );
}

export default PassedList;
