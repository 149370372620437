import React from 'react';
import { TextField } from '@mui/material';

function OnboardingTextField({ id, label, placeholder, defaultValue, onChange }) {
    return (
        <TextField
            sx={{ width: ['0.8', '0.8', '1'], margin: '2rem auto' }}
            className='onboarding_input'
            variant='outlined'
            id={id}
            label={label}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
        />
    );
}

export default OnboardingTextField;
