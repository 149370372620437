import React from 'react';
import { Link } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { AppStore } from '../../stores/AppStore';
import { useStoreState } from 'pullstate';
import { Button, Box } from '@mui/material';
import WelcomeChecklist from './WelcomeChecklist';
import './Welcome.css';
const Welcome = () => {
    const { userDoc } = useStoreState(AppStore);
    const { extensionInstalled, active } = userDoc;
    //set welcomeComplete to true
    const welcomeUser = async () => {
        try {
            const docRef = doc(db, 'users', userDoc.uid);
            const userData = { welcomeComplete: true };
            AppStore.update((s) => {
                s.userDoc = { ...s.userDoc, ...userData };
            });
            await setDoc(docRef, userData, { merge: true });
        } catch (error) {
            // Handle error here
            console.error(error);
        }
    };

    if (userDoc?.active) {
        // show nothing if the user has clicked to hide Welcome section
        // if desired, add button here to run unWelcomeUser() and show Welcome section
        return null;
    } else {
        // show this if the user hasn't yet clicked to hide the checkboxes

        return (
            <Box width={{ xs: '100%', md: '70%' }} className='Welcome'>
                <h1>Welcome{userDoc.firstName && ', ' + userDoc.firstName}! Let's get started.</h1>
                <p>
                    To start using Passed.AI, you need to{' '}
                    <Link to='/subscribe' variant='contained' color='primary'>
                        subscribe to a plan
                    </Link>{' '}
                    and{' '}
                    <a
                        href='https://chrome.google.com/webstore/detail/chatgpt-ai-detector-for-e/nmkodlgnddendacmkpghbnjmaknnegfo'
                        target='_blank'
                        rel='noreferrer'
                    >
                        install the Passed.AI extension
                    </a>{' '}
                    in Google Chrome.
                </p>
                <WelcomeChecklist installed={extensionInstalled} subscribed={active} />

                {extensionInstalled && active && (
                    <div>
                        <p>
                            You're ready to get started! You can click the button below to hide this
                            checklist.
                        </p>
                        <div style={{ height: '1.5rem' }} />
                        <Button
                            size='large'
                            color='secondary'
                            variant='contained'
                            className='passed-btn centered'
                            onClick={() => {
                                welcomeUser();
                            }}
                        >
                            All done!
                        </Button>
                        <div style={{ height: '1.5rem' }} />
                    </div>
                )}
            </Box>
        );
    }
};

export default Welcome;
