import { React, useState } from 'react';

import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

import Header from '../MUI/Header';
import { Box, Switch } from '@mui/material';

const DataManagement = () => {
    const { userDoc } = useStoreState(AppStore);
    const [toggle, setToggle] = useState(userDoc.useMyData);

    const handleSwitch = () => {
        AppStore.update((s) => {
            s.userDoc = {
                ...s.userDoc,
                useMyData: !s.userDoc.useMyData,
            };
        });

        setToggle(!toggle);
    };

    return (
        <Box className='manage'>
            <Header text='Manage your data' />
            <p>
                At Passed.AI, we take your data privacy seriously. To ensure that you have the best
                experience with our tool, we have two data settings that you can control:
            </p>
            <ol>
                <li>
                    <strong>Scan History:</strong> We automatically keep a history of your scans.
                    This history allows us to provide you with a seamless experience and is
                    essential for delivering our services effectively. Please note that this setting
                    cannot be changed as it's necessary for our tool to function as intended.
                </li>
                <li>
                    <strong>Anonymized Data Use:</strong> By default, we utilize your anonymized
                    data to continually improve our services and offer you a better user experience.
                    This usage is outlined in our Terms and Conditions, which you accepted upon
                    subscription. However, we understand the importance of choice when it comes to
                    your data. Therefore, this setting is toggleable, and you're free to opt-out at
                    any point should you choose to do so. If you choose to leave this setting
                    enabled, we greatly appreciate your contribution to the improvement and
                    effectiveness of our tool.
                </li>
            </ol>
            <p>
                Your data security is our priority. We appreciate your trust in us and are committed
                to maintaining it.
            </p>

            <Box className='data-container'>
                <Switch
                    checked={true}
                    disabled
                    inputProps={{ 'aria-label': 'switch A' }}
                    color='secondary'
                    sx={{ marginRight: '1rem' }}
                />
                <p>Allow Passed.AI to keep a history of my scans</p>
            </Box>
            <Box className='data-container'>
                <Switch
                    checked={toggle}
                    onChange={handleSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color='secondary'
                    sx={{ marginRight: '1rem' }}
                />
                <p>Allow Passed.AI to use anonymized data to improve their model</p>
            </Box>
        </Box>
    );
};

export default DataManagement;
