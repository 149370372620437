import { Backdrop, CircularProgress } from '@mui/material';
import Logo from '../../assets/images/logo-login-page.png';
import './Loading.css';

const Loading = () => {
    return (
        <div className='Loading'>
            <Backdrop
                sx={{ backgroud: '#fff', color: 'primary', opacity: '1', zIndex: 999 }}
                open={true}
                className='loading-backdrop'
            >
                <img src={Logo} alt='Passed.ai' className='loading-logo' />
                <CircularProgress color='primary' />
            </Backdrop>
        </div>
    );
};

export default Loading;
