import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const HighScore = () => {
    return (
        <>
            <Box sx={{ borderLeft: '3px solid #e55934' }}>
                <Typography
                    variant='body1'
                    sx={{ textAlign: 'left', marginBottom: 2, marginLeft: '1rem' }}
                >
                    <strong>High AI detection scores can be caused by:</strong>
                </Typography>
                <List sx={{ marginLeft: '3rem' }}>
                    <ListItem disablePadding>
                        <ListItemText
                            primaryTypographyProps={{ component: 'div' }}
                            primary={
                                <>
                                    <strong>Using AI Tools:</strong> Tools like Grammarly or ChatGPT
                                    leave certain patterns that our AI detector recognizes,
                                    resulting in a high AI confidence score.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText
                            primaryTypographyProps={{ component: 'div' }}
                            primary={
                                <>
                                    <strong>Short Pieces:</strong> Brief content provides less
                                    information for AI to analyze, often resulting in a higher
                                    suspicion of AI creation.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText
                            primaryTypographyProps={{ component: 'div' }}
                            primary={
                                <>
                                    <strong>Routine Formats:</strong> Writing that follows common
                                    structures, like standard essays, might appear AI-made because
                                    AI often uses predictable patterns.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText
                            primaryTypographyProps={{ component: 'div' }}
                            primary={
                                <>
                                    <strong>Partial Reviews:</strong> Scanning only parts of the
                                    document, for example just the intro or conclusion, can lead to
                                    high AI detection scores because the AI doesn't get the full
                                    context. We recommend scanning full documents for a more
                                    accurate AI score.
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </Box>
        </>
    );
};

export default HighScore;
