import {
    AppBar,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useStoreState } from 'pullstate';
// import Logo from '../../assets/images/Blue-Inline.png';
import Logo from '../../assets/images/blue-inline.svg';
import { AppStore } from '../../stores/AppStore';
import { NavLink } from 'react-router-dom';
import './Navigation.css';
import DrawerFooter from './DrawerFooter/DrawerFooter';
import { useState, useEffect } from 'react';
import PassedIcon from '../PassedIcon/PassedIcon';
import CreditsChip from '../CreditsChip/CreditsChip';

const Navigation = () => {
    const location = window.location.pathname.split('/')[1];
    const [displaySidebar, setDisplaySidebar] = useState(true);
    const { drawerWidth, userDoc } = useStoreState(AppStore);
    const navItems = [
        {
            name: 'Subscribe',
            icon: (
                <PassedIcon
                    iconPath='M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z'
                    disabled={userDoc.active}
                />
            ),
            link: '/subscribe',
            divider: true,
            displayInactive: true,
        },
        {
            name: 'Dashboard',
            icon: (
                <PassedIcon
                    iconPath='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/dashboard',
            divider: true,
        },
        {
            name: 'Document Scanner',
            icon: (
                <PassedIcon
                    iconPath='M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z'
                    disabled={!userDoc.active && !userDoc.credits > 0}
                />
            ),
            link: '/scan',
        },
        {
            name: 'Scan History',
            icon: (
                <PassedIcon
                    iconPath='M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z'
                    disabled={!userDoc.active && !userDoc.credits > 0}
                />
            ),
            link: '/scans',
            divider: true,
        },
        // {
        //     name: 'Assignments',
        //     icon: (
        //         <PassedIcon
        //             iconPath={`M4 7h16v2H4V7zm0 6h16v-2H4v2zm0 4h7v-2H4v2zm0 4h7v-2H4v2zm11.41-2.83L14 16.75l-1.41 1.41L15.41 21 20 16.42 18.58 15l-3.17 3.17zM4 3v2h16V3H4z`}
        //             disabled={!userDoc.active}
        //         />
        //     ),
        //     link: '/assignments',
        // },
        {
            name: 'Courses',
            icon: (
                <PassedIcon
                    iconPath='m19 1-5 5v11l5-4.5V1zM1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5V6c-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6zm22 13.5V6c-.6-.45-1.25-.75-2-1v13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5v2c1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5v-1.1z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/courses',
        },
        {
            name: 'Students',
            icon: (
                <PassedIcon
                    iconPath='M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3 1 9l11 6 9-4.91V17h2V9L12 3z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/students',
            divider: true,
        },
        {
            name: 'Add Credits',
            icon: (
                <PassedIcon
                    iconPath='m21.9 8.89-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9 1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52 9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41-.25-.29-.37-.68-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/credits',
        },
        {
            name: 'Help',
            icon: (
                <PassedIcon
                    iconPath='M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3 1 9l11 6 9-4.91V17h2V9L12 3z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/help',
        },
        {
            name: 'Become an affiliate',
            icon: (
                <PassedIcon
                    iconPath='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm.88 15.76V19h-1.75v-1.29c-.74-.18-2.39-.77-3.02-2.96l1.65-.67c.06.22.58 2.09 2.4 2.09.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31 0-.1.01-2.4 2.62-2.96V5h1.75v1.24c1.84.32 2.51 1.79 2.66 2.23l-1.58.67c-.11-.35-.59-1.34-1.9-1.34-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45 0 2.63-2.5 3.13-3.02 3.22z'
                    disabled={!userDoc.active}
                />
            ),
            link: '/affiliate',
        },
    ];

    const mobile = useMediaQuery('(max-width:650px)');
    // const windowSize = useRef(window.innerWidth);
    useEffect(() => {
        if (mobile) {
            setDisplaySidebar(false);
        }
    }, [mobile]);

    const filteredNavItems = navItems.filter((item) => {
        if (item.name === 'Subscribe' && userDoc.active) {
            return false;
        } else if (item.name === 'Subscribe' && !userDoc.active) {
            // console.log('reached');
            return true;
        }
        return true;
    });

    const toggleSidebar = () => {
        setDisplaySidebar(!displaySidebar);
    };

    if (!(location === 'ext-scan' || location === 'ext-scans')) {
        return (
            <>
                <AppBar
                    position='fixed'
                    display='flex'
                    flex='1'
                    sx={{ width: '100%', ml: `${drawerWidth}px` }}
                >
                    <Toolbar className='nav-toolbar'>
                        <MenuIcon onClick={toggleSidebar} style={{ cursor: 'pointer' }} />
                        <Typography variant='h6' noWrap component='div'>
                            <CreditsChip />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    style={
                        mobile
                            ? displaySidebar
                                ? { display: 'block' }
                                : { display: 'none' }
                            : { display: 'block' }
                    }
                    sx={{
                        width: 280,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 280,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant='permanent'
                    anchor='left'
                >
                    <Toolbar
                        style={{
                            display: mobile ? 'flex' : 'none',
                            flexDirection: 'row-reverse',
                            backgroundColor: '#1f2641',
                            color: 'white',
                        }}
                    >
                        <KeyboardDoubleArrowLeftIcon
                            style={mobile ? { display: 'block' } : { display: 'none' }}
                            onClick={toggleSidebar}
                        />
                    </Toolbar>
                    <Toolbar>
                        <img src={Logo} alt='Passed.ai' className='header-logo' />
                        {/* <Chip label='Beta' size='small' color='secondary' /> */}
                    </Toolbar>
                    <Divider />
                    <List>
                        {filteredNavItems.map((item, i) => {
                            return (
                                <div key={i} onClick={toggleSidebar}>
                                    {(userDoc.credits > 0 && item.name === 'Document Scanner') ||
                                    item.name === 'Scan History' ? (
                                        <NavLink className='side-nav-link' to={item.link}>
                                            <ListItem>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.name} />
                                            </ListItem>
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            className={`side-nav-link${
                                                !userDoc.active && !item.displayInactive
                                                    ? ' disabled-link'
                                                    : ''
                                            }`}
                                            to={item.link}
                                        >
                                            <ListItem>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.name} />
                                            </ListItem>
                                        </NavLink>
                                    )}
                                    {item.divider && <Divider />}
                                </div>
                            );
                        })}
                    </List>
                    <DrawerFooter width={drawerWidth} toggleSidebar={toggleSidebar} />
                </Drawer>
            </>
        );
    } else {
        return;
    }
};

export default Navigation;
