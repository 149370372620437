import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import './ScanModelSelect.css';
import { CurrentScan } from '../../../stores/CurrentScan';
import { useStoreState } from 'pullstate';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
const ScanModelSelect = () => {
    const { model } = useStoreState(CurrentScan);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0.25,
            }}
        >
            <FormControl>
                <InputLabel id='model-select-label'>AI Detection</InputLabel>
                <Select
                    labelId='model-select-label'
                    id='model-select'
                    value={model}
                    label='AI Detection'
                    onChange={(e) => {
                        CurrentScan.update((s) => {
                            s.model = e.target.value;
                        });
                    }}
                >
                    <MenuItem value={1}>Standard Model (2.0)</MenuItem>
                    <MenuItem value={2}>Turbo Model (3.0)</MenuItem>
                    <MenuItem value={3}>Multi Language</MenuItem>
                </Select>
            </FormControl>
            <Tooltip
                title={
                    <Typography>
                        Model 3.0 Turbo Released: 3.0 Turbo is the MOST accurate but can be
                        sensitive. Use it if you have a very low tolerance for any AI being used.
                        Use 2.0 Standard if some AI editing is allowed.
                    </Typography>
                }
                placement='top'
                sx={{ fontSize: '1.5rem' }}
            >
                <IconButton>
                    <HelpOutlineOutlinedIcon
                        sx={{
                            fontSize: '1.5rem',
                        }}
                    />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ScanModelSelect;
