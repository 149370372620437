import { GppGoodOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { splitSentences } from '../../../utils/scanFns';
import { AppStore } from '../../../stores/AppStore';
import { getSubCollectionData } from '../../../utils/getData';
import { useNavigate } from 'react-router-dom';
import { LMSStore } from '../../../stores/LMSStore';

const ScanButton = ({ children }) => {
    const navigate = useNavigate();
    const location = window.location.pathname;
    const { userDoc } = useStoreState(AppStore);
    const {
        aiCheck,
        plagiarismCheck,
        content,
        wordCount,
        credits,
        title,
        course,
        student,
        id,
        loading,
        model,
    } = useStoreState(CurrentScan);
    const scanType = aiCheck && plagiarismCheck ? 0 : plagiarismCheck ? 2 : 1;
    const scanNow = () => {
        const scanId = id || 'app-scan-' + title + '-' + Date.now();
        CurrentScan.update((s) => {
            s.scanDisabled = true;
            s.error = false;
            s.scanId = scanId;
            s.loading = true;
        });
        const sentences = splitSentences(content);
        const scanTitle = title ? title : content.substring(0, 50);

        fetch(`https://us-central1-passedai.cloudfunctions.net/passedAppScan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sentences: sentences,
                originalContent: content,
                creditCost: credits,
                scanType: scanType,
                title: scanTitle,
                user: userDoc.uid,
                course: course,
                student: student,
                id: scanId,
                aiModelVersion: model,
            }),
        })
            .then((response) => {
                // console.log('originality response', response);
                if (response.status === 200) {
                    AppStore.update((s) => {
                        s.userDoc.credits = s.userDoc.credits - credits;
                    });
                    return response.json();
                } else {
                    console.error('error getting score', response);
                    CurrentScan.update((s) => {
                        s.error = true;
                        s.errorMessage =
                            'There was an error running your scan. Please try again and contact support if the problem persists.';
                        s.errorCTA = 'Contact Support';
                        s.scanDisabled = false;
                        s.loading = false;
                    });
                }
            })
            .then((data) => {
                // console.log('originality data', data?.result);
                if (data?.result?.code === 'ERR_BAD_REQUEST') {
                    CurrentScan.update((s) => {
                        s.error = true;
                        s.errorMessage =
                            'There was an error running your scan. Please try again and contact support if the problem persists.';
                        s.errorCTA = 'Contact Support';
                        s.scanDisabled = false;
                        s.loading = false;
                    });
                    return;
                } else {
                    CurrentScan.update((s) => {
                        s.scanDisabled = false;
                    });

                    getSubCollectionData(userDoc.uid, 'scans', LMSStore);
                    getSubCollectionData(userDoc.uid, 'students', LMSStore);
                    getSubCollectionData(userDoc.uid, 'courses', LMSStore);

                    CurrentScan.update((s) => {
                        s.scanDisabled = false;
                        s.loading = false;
                    });
                    // console.log('location', location);
                    //navigate to results page /scans/data.id
                    if (location === '/ext-scan') {
                        // console.log('ext scan');
                        navigate('/ext-scans/' + data.id);
                    } else {
                        // console.log('app scan');
                        navigate('/scans/' + data.id);
                    }
                }
            })
            .catch((err) => {
                console.log('error', err);
                CurrentScan.update((s) => {
                    s.error = true;
                    s.scanDisabled = false;
                    s.loading = false;
                });
            });
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {children}
            <LoadingButton
                disabled={
                    wordCount < 50 ||
                    credits > userDoc.credits ||
                    (!aiCheck && !plagiarismCheck) ||
                    (wordCount > 2999 && plagiarismCheck)
                }
                size='large'
                loading={loading}
                loadingPosition='start'
                startIcon={<GppGoodOutlined />}
                variant='contained'
                color='secondary'
                onClick={scanNow}
            >
                {!loading ? 'Scan Now' : 'Scanning...'}
            </LoadingButton>
        </Box>
    );
};

export default ScanButton;
