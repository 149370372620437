import { useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { Box, Card, CardContent, CardActions } from '@mui/material';
import { addDoc, collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './Credits.css';
import Header from '../../components/MUI/Header';
import '../../components/MUI/MuiStyles.css';
import Subheader from '../../components/MUI/Subheader';
import PassedLoadingButton from '../../components/MUI/PassedLoadingButton';
import CartRobot from '../../assets/images/CartRobot.gif';
import useMediaQuery from '@mui/material/useMediaQuery';

const Credits = () => {
    const { plans } = useStoreState(AppStore);
    const { user } = useStoreState(AppStore);
    const [loading, setLoading] = useState(false);
    const desktop = useMediaQuery('(max-height:775px), (max-width:1200px)');
    // function to flag the user as having started payment
    const setPaymentStarted = async () => {
        try {
            const cartDocRef = doc(db, 'users', user.uid);
            const userData = { paymentStarted: true };
            await setDoc(cartDocRef, userData, { merge: true });
        } catch (error) {
            console.error(error);
        }
    };

    const buyCredits = (val) => {
        setLoading(true);
        const purchase = async () => {
            const docRef = await addDoc(collection(db, 'users', user.uid, 'checkout_sessions'), {
                line_items: [
                    {
                        price_data: {
                            currency: 'usd',
                            product_data: {
                                name: 'Passed Credits',
                            },
                            unit_amount: 1,
                        },
                        quantity: val,
                    },
                ],
                payment_intent_data: {
                    metadata: {
                        user_id: user.uid,
                    },
                },
                mode: 'payment',
                success_url: window.location.href + '/?success=credits',
                cancel_url: window.location.href + '/?cancelled=true',
                allow_promotion_codes: true,
                collect_shipping_address: false,
            });
            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(docRef, (snap) => {
                // console.log('snap', snap.data());
                const { error, url } = snap.data();
                // console.log('error', error);
                // console.log('url', url);
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    console.log(`An error occurred: ${error.message}`);
                }
                if (url) {
                    //first set the paymentStarted flag to true in Firestore
                    setPaymentStarted();
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                    setLoading(false);
                }
            });
        };

        purchase();
    };

    // 20 dollar plan: prod_Np81hz6LwWBJZ7
    // 10 dollar plan: prod_Np803Xp5yrIL4b
    // 5 dollar plan: prod_Np8015pAUizvBP

    //PREVIOUS
    // const filteredPlans = plans?.filter((plan) => {
    //     if (plan?.prices[0]?.interval === null) {
    //         return true;
    //     }
    //     return false;
    // });

    //NEW
    const productIds = ['prod_Np81hz6LwWBJZ7', 'prod_Np803Xp5yrIL4b', 'prod_Np8015pAUizvBP'];
    const filteredPlans = plans?.filter((plan) => {
        return productIds.includes(plan?.prices[0]?.product);
    });
    const displayPlans = filteredPlans?.sort(
        (a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount
    );

    return (
        <Box className='mui-box'>
            <Header text='Add Credits' />

            <Box
                style={{ display: 'flex', gap: '2rem', margin: '1rem' }}
                flexDirection={{ xs: 'column', md: 'row' }}
            >
                {displayPlans?.map((plan, i) => (
                    <Card
                        className='mui-box'
                        style={{ backgroundColor: '#F0F0F0', padding: '1rem' }}
                        key={i}
                        elevation={2}
                    >
                        <CardContent>
                            <Header text={`${plan.name}`} />
                            {plan?.description?.split('.').map((desc, i) => (
                                <Subheader key={i} text={`${desc}`} />
                            ))}
                            <Header text={`US $${plan.prices[0].unit_amount / 100}.00`} />
                        </CardContent>
                        <CardActions>
                            <PassedLoadingButton
                                loading={loading}
                                onClick={() =>
                                    loading ? null : buyCredits(plan.prices[0].unit_amount)
                                }
                                text={`Add ${plan.name.split(' ', 2)[0]} Credits`}
                            />
                        </CardActions>
                    </Card>
                ))}
            </Box>
            {!desktop && (
                <Box style={{ right: 20, bottom: 20, position: 'fixed' }}>
                    <img className='robot-app' src={CartRobot} alt='Robot'></img>
                </Box>
            )}
        </Box>
    );
};

export default Credits;
