import { Chip } from '@mui/material';
import { useStoreState } from 'pullstate';
import { Link } from 'react-router-dom';
import { AppStore } from '../../stores/AppStore';

const CreditsChip = () => {
    const pathname = window.location.pathname;
    const { userDoc } = useStoreState(AppStore);
    if (pathname === '/ext-scan') {
        return (
            <Chip
                label={userDoc?.credits ? 'Credits: ' + userDoc?.credits : 'Credits: 0'}
                sx={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: 'white', // set the text color that contrasts your gradient colors
                    backgroundImage: 'linear-gradient(45deg, #30bced 50%, #6ECFF1 100%)',
                    textDecoration: 'none',
                }}
            />
        );
    } else {
        return (
            <Link className='creditsChip' to={'/credits'}>
                <Chip
                    label={userDoc?.credits ? 'Credits: ' + userDoc?.credits : 'Credits: 0'}
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        color: 'white', // set the text color that contrasts your gradient colors
                        backgroundImage: 'linear-gradient(45deg, #30bced 50%, #6ECFF1 100%)',
                        textDecoration: 'none',
                    }}
                />
            </Link>
        );
    }
};

export default CreditsChip;
