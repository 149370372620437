import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Snackbar,
    TextField,
    Unstable_Grid2 as Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SubjectList from '../SubjectList/SubjectList';
import { Save, ThumbUp, ThumbDown } from '@mui/icons-material';

const AccountCard = () => {
    const { userDoc } = useStoreState(AppStore);
    const { firstName, lastName, institution, level, uid } = userDoc;
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if (success || error) {
            setTimeout(() => {
                setSuccess(false);
                setError(false);
            }, 5000);
        }
    }, [success, error]);

    const updateUserDoc = (key, value) => {
        if ((key === 'firstName' || key === 'lastName') && (value === undefined || value === '')) {
            setError(true);
            setErrorMsg('Please fill out all fields');
        }
        AppStore.update((s) => {
            s.userDoc = {
                ...s.userDoc,
                [key]: value,
            };
        });
    };
    const updateDoc = () => {
        setSaving(true);
        const docRef = doc(db, 'users', uid);

        setDoc(docRef, userDoc, { merge: true })
            .then(() => {
                console.log('document uploaded successfully');
                setSaving(false);
                setSuccess(true);
            })
            .catch((error) => {
                setSaving(false);
                setError(true);
                console.log('error', error);
            });
    };

    const levelOptions = [
        {
            value: 'high school',
            label: 'High School',
        },
        {
            value: 'undergraduate',
            label: 'Undergraduate',
        },
        {
            value: 'graduate',
            label: 'Graduate',
        },
    ];

    return (
        <>
            <Snackbar
                sx={{ width: '100%' }}
                open={success || error}
                autoHideDuration={5000}
                onClose={() => {
                    setError(false);
                    setSuccess(false);
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {success && !error ? (
                    <Alert severity='success' sx={{ width: '400px', margin: '0 auto' }}>
                        Your account has been updated
                    </Alert>
                ) : !success && error ? (
                    <Alert severity='error' sx={{ width: '400px', margin: '0 auto' }}>
                        {errorMsg
                            ? errorMsg
                            : `We've encountered an error. Please try again later.`}
                    </Alert>
                ) : null}
            </Snackbar>
            <div>
                <Card>
                    <CardHeader title='User Information' />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label='First Name'
                                    name='firstname'
                                    onChange={(e) => {
                                        updateUserDoc('firstName', e.target.value);
                                        updateUserDoc(
                                            'displayName',
                                            e.target.value + ' ' + userDoc.lastName
                                        );
                                    }}
                                    defaultValue={firstName}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label='Last Name'
                                    name='lastname'
                                    onChange={(e) => {
                                        updateUserDoc('lastName', e.target.value);
                                        updateUserDoc(
                                            'displayName',
                                            userDoc.firstName + ' ' + e.target.value
                                        );
                                    }}
                                    defaultValue={lastName}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label='Institution'
                                    name='institution'
                                    onChange={(e) => {
                                        updateUserDoc('institution', e.target.value);
                                    }}
                                    defaultValue={institution}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label='Institution Level'
                                    name='institution level'
                                    onChange={(e) => {
                                        updateUserDoc('level', e.target.value);
                                    }}
                                    select
                                    SelectProps={{ native: true }}
                                    defaultValue={level}
                                >
                                    {levelOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <SubjectList />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider variant='middle' orientation='vertical' />

                    <CardActions
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid xs={12}>
                                <LoadingButton
                                    disabled={saving || !firstName || !lastName}
                                    className='passed-btn'
                                    style={{ margin: '1.5rem 0' }}
                                    onClick={updateDoc}
                                    variant='contained'
                                    loading={saving}
                                    loadingPosition='start'
                                    color={success ? 'success' : error ? 'error' : 'secondary'}
                                    startIcon={
                                        success ? <ThumbUp /> : error ? <ThumbDown /> : <Save />
                                    }
                                >
                                    Update Info
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default AccountCard;
