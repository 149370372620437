import { Box, Chip, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useStoreState } from 'pullstate';
import { hsSubjects, collegeSubjects, gradSubjects } from '../../../assets/data/subject';
import { OnboardingStore } from '../../../stores/OnboardingStore';
import DetectiveRobot from '../../../assets/images/DetectiveRobot.gif';

import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';
import RobotBox from '../../../components/Onboarding/RobotBox';
import Footer from '../../../components/Onboarding/Footer';
import EmptyRobotBox from '../../../components/Onboarding/EmptyRobotBox';

const Step4 = () => {
    const { userDoc } = useStoreState(OnboardingStore);
    // console.log('userDocstep4', userDoc);
    const { level, subjects, institution } = userDoc;
    const title = 'Educator Profile';
    const subTitle = `Help us help you shine! \nTell us a little bit about how you'll use Passed.ai.`;
    const subjectList =
        level === 'high school'
            ? hsSubjects
            : level === 'undergraduate'
            ? collegeSubjects
            : gradSubjects;
    return (
        <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
            <Box textAlign={{ xs: 'center' }} className='onboarding_step3_container'>
                <EmptyRobotBox />
                <Box width={{ xs: '100%', md: '50rem' }} className='onboarding_step step_two'>
                    <OnboardingHeader title={title} subtitle={subTitle} step={2} />

                    <Box className='onboarding_step_content'>
                        <Box
                            margin={{ xs: '1rem', md: '2rem' }}
                            className='onboarding_step_content_item col'
                        >
                            <InputLabel id='level-label'>Level</InputLabel>
                            <Select
                                sx={{ width: ['0.8', '0.8', '1'] }}
                                labelId='level-label'
                                id='level-select'
                                value={level || '-'}
                                label='Level'
                                placeholder='Select your level'
                                onChange={(e) =>
                                    OnboardingStore.update((s) => {
                                        s.userDoc.level = e.target.value;
                                    })
                                }
                            >
                                <MenuItem disabled value={'-'}>
                                    Select Level
                                </MenuItem>
                                <MenuItem value={'graduate'}>Graduate</MenuItem>
                                <MenuItem value={'undergraduate'}>Undergraduate</MenuItem>
                                <MenuItem value={'high school'}>High School</MenuItem>
                            </Select>
                        </Box>
                        <Box
                            margin={{ xs: '1rem', md: '2rem' }}
                            className='onboarding_step_content_item col'
                        >
                            <InputLabel id='subject-select-label'>Subjects Taught</InputLabel>
                            <Select
                                sx={{ width: ['0.8', '0.8', '1'] }}
                                disabled={!level}
                                labelId='subject-select-label'
                                id='subject-select-chip'
                                multiple
                                value={subjects}
                                onChange={(e) =>
                                    OnboardingStore.update((s) => {
                                        s.userDoc.subjects = e.target.value;
                                    })
                                }
                                input={<OutlinedInput id='subject-select-chip' label='Chip' />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>Placeholder</em>;
                                    } else
                                        return (
                                            <Box
                                                sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                            >
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        );
                                }}
                            >
                                {subjectList.map((subject, i) => (
                                    <MenuItem key={i} value={subject}>
                                        {subject}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        <Box
                            margin={{ xs: '1rem', md: '2rem' }}
                            className='onboarding_step_content_item col'
                        >
                            <InputLabel>School or Institution</InputLabel>
                            <TextField
                                sx={{ width: ['0.8', '0.8', '1'] }}
                                className='onboarding_input'
                                variant='outlined'
                                id='Institution'
                                label=''
                                value={institution || ''}
                                placeholder='e.g. Passed Academy'
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.institution = e.target.value;
                                    });
                                }}
                            >
                                Institution
                            </TextField>
                        </Box>
                    </Box>
                </Box>
                <RobotBox robot={DetectiveRobot} />
            </Box>
            <Footer
                disabled={!level || subjects.length === 0}
                nextStep={6}
                previousStep={3}
                backButton={true}
            />
        </Box>
    );
};

export default Step4;
